import { Button, Card, CardFooter, Typography } from "@material-tailwind/react";
import {
  PlusIcon,
  TrashIcon,
  PencilIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
  import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import "moment/locale/pt-br"; // Importe o locale necessário para o formato da data e hora
import moment from "moment";
import styled from "styled-components";
import { Calendar, View } from "react-big-calendar";
import { Tooltip } from "@mui/material";
import Select from "react-select";

import { AuthContext } from "../../contexts/Auth/AuthContext";
import MessageBox from "./MessageBox";
import { GeralContext } from "../../contexts/Geral/GeralContext";

const StyledCalendar = styled(Calendar)`
  .rbc-calendar {
    height: 100vh; /* Ocupa toda a altura da viewport */
    width: 100vw; /* Ocupa toda a largura da viewport */
    margin: 0;
    padding: 0;
    overflow: visible !important; /* Permite que o conteúdo exceda os limites */
  }

  .rbc-time-slot {
    height: auto; /* Ajusta a altura automaticamente */
  }

  .rbc-timeslot-group {
    min-height: 65px; /* Garante altura mínima */
  }

  .rbc-time-gutter {
    z-index: 10;
  }

  .rbc-allday-cell {
    height: 0 !important; /* Força a altura da célula a ser 0 */
    overflow: hidden;
  }

  .rbc-allday-cell .rbc-row-bg,
  .rbc-allday-cell .rbc-day-bg,
  .rbc-allday-cell .rbc-row-content,
  .rbc-allday-cell .rbc-row {
    height: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .rbc-event {
    position: absolute !important; /* Permite o posicionamento dentro do time slot */
    border-radius: 4px !important; /* Bordas arredondadas */
    padding: 0 !important; /* Remove padding interno */
    margin: 0.5% !important; /* Margem de 2% em todos os lados */
    box-sizing: border-box !important; /* Inclui padding e borda no cálculo da largura e altura */
    overflow: visible !important; /* Garante que o conteúdo não seja cortado */
    color: black !important; /* Ajusta a cor do texto */
    border: 0.5px solid #333 !important; /* Borda cinza escuro mais fina */
    font-size: 10px !important; /* Ajusta o tamanho da fonte */
    cursor: pointer !important; /* Força o ponteiro do mouse a ser uma mão */
    display: flex; /* Adiciona flexbox para centralizar o conteúdo */
    align-items: center; /* Alinha o conteúdo verticalmente no centro */
    justify-content: center; /* Alinha o conteúdo horizontalmente no centro */
    left: 0 !important; /* Garante que o evento comece no canto esquerdo do slot */
    width: 98% !important; /* Ocupa todo o espaço horizontal menos a margem de 2% em cada lado */
    z-index: 10; /* Coloca o rótulo abaixo do evento */
  }

  .rbc-event-content {
    color: black !important;
    font-size: 10px !important; /* Ajusta o tamanho da fonte */
    border-radius: 4px !important; /* Bordas arredondadas */
    padding: 0 !important; /* Remove padding interno */
    margin: 0 !important; /* Remove margens adicionais */
    box-sizing: border-box !important; /* Inclui padding e borda no cálculo da largura e altura */
    text-align: center; /* Centraliza o texto */
    width: 98% !important; /* Ocupa todo o espaço horizontal menos a margem de 2% em cada lado */
    z-index: 10; /* Coloca o rótulo abaixo do evento */
    overflow: visible !important; /* Garante que o texto completo seja exibido */
    word-wrap: break-word !important; /* Força a quebra de palavras longas */
    white-space: normal !important; /* Permite múltiplas linhas no texto */
    line-height: 1.2 !important; /* Ajusta a altura das linhas para evitar cortes */
  }

  .rbc-time-slot,
  .rbc-time-slot .rbc-timeslot-group > .rbc-time-slot {
    cursor: pointer !important; /* Força o ponteiro do mouse a ser uma mão */
    min-height: 65px !important; /* Garante altura mínima suficiente para evitar cortes */
    overflow: visible !important; /* Exibe todo o conteúdo */
  }

  .rbc-event-label {
    font-family: Arial, sans-serif; /* Ou Verdana, Tahoma, etc. */
    line-height: 1.2; /* Espaçamento entre linhas para melhor legibilidade */
    position: relative; /* Necessário para z-index dentro do rótulo */
    font-size: 10px !important; /* Ajusta o tamanho da fonte */
    z-index: 10; /* Coloca o rótulo abaixo do evento */
    pointer-events: none; /* Faz com que o rótulo não receba eventos de clique */
    width: 98%;
    overflow-wrap: break-word;
    white-space: normal;
    word-wrap: break-word;
  }
`;



interface ResourceHeaderProps<T = {}> {
  label: string;
  resource?: T;
}

const CustomResourceHeader: React.FC<
  ResourceHeaderProps<{
    id?: string;
    subtitle1?: string;
    subtitle2?: string;
    subtitle3?: string;
  }>
> = ({ label, resource }) => {
  return (
    <div>
      <span>{label}</span>
      <br />
      <small style={{ fontSize: "12px" }}>{resource?.subtitle1}</small>
      <br />
      <small style={{ fontSize: "10px" }}>{resource?.subtitle2}</small>
      <br />
      <small style={{ fontSize: "10px" }}>{resource?.subtitle3}</small>
    </div>
  );
};

interface AgendaItem {
  id_agenda: string | null;
  nome_paciente: string;
  id_pac: string;
  codconvenio: string;
  dt_evento: string;
  hr_inicio: string;
  hr_fim: string;
  nome_terapeuta: string;
  id_terap: string;
  paciente: string;
  convenio: string;
  n_sala: string;
  codproc: string;
  atividade: string;
  horario: string;
  tmp_atividade: string;
  situacao: string; // Nova propriedade para a situação do evento
  observacao: string;
}
interface ConvenioItem {
  codconvenio: string;
  convenio: string;
}

interface AtividadeItem {
  id_ativ: string;
  atividade: string;
  forma: string;
  n_pessoas: number;
  tmp_atividade: string;
}

interface ConvenioSelecionado {
  codconvenio: string;
  ncartao: string | null;
}

interface RoomEvent {
  resourceId: string;
  color: string;
  start: Date; // Adicionar a propriedade start do tipo Date
  end: Date; // Adicionar a propriedade end do tipo Date
  idAgenda: string;
  idPac: string;
  idTerap: string;
  nomeTerap: string;
  convenio: string;
  situacao: string;
}

interface CustomCalendarProps {
  tableAgenda: AgendaItem[];
  tablePac: any[]; // Substitua any pelo tipo correto
  tableSala: any[]; // Substitua any pelo tipo correto
  tableTerap: any[]; // Substitua any pelo tipo correto
  localizer: any; // Substitua any pelo tipo correto
  minTime: Date; // Horário mínimo
  maxTime: Date; // Horário máximo
  selectedDate: Date; // Adicionar a propriedade selectedDate do tipo Date
}
type CalendarView = "month" | "week" | "day" | "agenda" | "work_week";
type Turno = {
  atividade?: string;
  terapeuta?: string;
  monitor?: string;
};
// Ajuste o componente para aceitar as props corretas

const CustomCalendar: React.FC<CustomCalendarProps> = ({
  tablePac,
  tableSala,
  tableTerap,
  localizer,
  minTime,
  maxTime,
  selectedDate,
}) => {
  const { agendaRecepcaoActions } = useContext(GeralContext);
  const { atividadeActions } = useContext(GeralContext);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedPaciente, setSelectedPaciente] = useState("");
  const [selectedSala, setSelectedSala] = useState<string | null>(null);
  const [selectedTerapeuta, setSelectedTerapeuta] = useState("");
  const [tableAtividade, setTableAtividade] = useState<AtividadeItem[]>([]);

  const [selectedEvent, setSelectedEvent] = useState<RoomEvent | null>(null);
  const [eventDate, setEventDate] = useState<string | null>(null);
  const [eventStartTime, setEventStartTime] = useState("");
  const [eventEndTime, setEventEndTime] = useState("");
  const [codProc, setCodproc] = useState("");
  const [nomeTerap, setNomeTerap] = useState("");
 
  const [atividade, setAtividade] = useState<string>("");
  const [selectedConvenio, setSelectedConvenio] = useState("");
  const { convenioActions } = useContext(GeralContext);
  const [observacao, setObservacao] = useState<string>("");
  const auth = useContext(AuthContext);
  const IsAdministrador = !!(
    auth.user?.ocupacao === "Administrador" || auth.user?.admin
  );
  const IsAdministradorSupervisor = !!(
    auth.user?.ocupacao === "Administrador" ||
    auth.user?.admin ||
    auth.user?.supervisor
  );
  const IsTerapeuta = !!(auth.user?.ocupacao === "Terapeuta");
  const IsMonitor = !!(auth.user?.ocupacao === "Monitor");
  const IsAdministrativo = auth.user?.ocupacao === "Administrativo";
  const AdministrativoPermissao = auth.user?.permissao;
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<string | null>(null);

  const [tmpAtividade, setTmpAtividade] = useState("");
  const [eventSituation, setEventSituation] = useState("");
  const [idAgenda, setIdAgenda] = useState<string>("");
  const [tableAgenda, setTableAgenda] = useState<AgendaItem[]>([]);
  const [view, setView] = useState<CalendarView>("day");
const [sentSelectedDate, setSentSelectedDate] = useState(() => {
  const storedDate = localStorage.getItem("selectedDateManha");
  return storedDate ? new Date(storedDate) : new Date();
});
  
  const idUser = auth.user?.id_user;

  const [conveniosDisponiveis, setConveniosDisponiveis] = React.useState<
    ConvenioItem[]
  >([]);
  const [conveniosPaciente, setConveniosPaciente] = React.useState<
    ConvenioItem[]
  >([]);


     
  // Função para buscar os convênios disponíveis
  useEffect(() => {
    const fetchConvenioData = async () => {
      try {
        const convenioData = await convenioActions.fill();
        setConveniosDisponiveis(convenioData);
      } catch (error) {
        console.error("Erro ao buscar dados dos convenios:", error);
      }
    };
    fetchConvenioData();
  }, [convenioActions]);

  // Convertendo minTime e maxTime para incluir apenas o horário
  const minTimeHour = minTime.getHours();
  const minTimeMinute = minTime.getMinutes();
  const maxTimeHour = maxTime.getHours();
  const maxTimeMinute = maxTime.getMinutes();

  // Função para determinar o turno com base no maxTimeHour

  const getTurno = (maxTimeHour: number): "manha" | "tarde" => {
    return maxTimeHour > 12 ? "tarde" : "manha";
  };
  const rooms = [
    ...tableSala
      .map((sala) => {
        // Itera sobre todos os dias da semana e pega o primeiro turno válido
        const diasDaSemana = Object.keys(sala.turnos);

        // Define o turno com base no maxTimeHour
        const turnoAtual = getTurno(maxTimeHour);

        // Função auxiliar para pegar o primeiro turno válido de acordo com o turno atual
        const getValidTurno = (dia: string): Turno | undefined => {
          const turno = sala.turnos[dia][turnoAtual];
          return turno.atividade ? turno : undefined;
        };

        // Extrai os turnos para cada dia da semana
        const diasComTurno = diasDaSemana
          .map((dia) => getValidTurno(dia))
          .filter((turno) => turno);

        // Se não houver turnos válidos, define valores padrão
        const primeiroTurno =
          diasComTurno.length > 0 ? diasComTurno[0] : undefined;

        // Extrai 'atividade', 'terapeuta' e 'monitor', com valores padrão se primeiroTurno for undefined
        const atividade = primeiroTurno?.atividade ?? "Não definida";
        const terapeuta = primeiroTurno?.terapeuta ?? "Não definido";
        const monitor = primeiroTurno?.monitor ?? "Não definido";

        // Buscar a atividade no tableAtividade para verificar a forma
        const atividadeEncontrada = tableAtividade.find(
          (item) => item.atividade === atividade
        );

        // Verifica se a forma da atividade é 'I'
        if (atividadeEncontrada?.forma === "I") {
          return {
            id: `${sala.n_sala}`,
            title: `${sala.n_sala}`,
            subtitle1: `${atividade}`,
            subtitle2: `${terapeuta}`,
            subtitle3: `${monitor ? monitor : "-"}`,
          };
        }

        return null; // Retorna null se a forma não for 'I'
      })
      .filter(
        (
          sala
        ): sala is {
          id: string;
          title: string;
          subtitle1: string;
          subtitle2: string;
          subtitle3: string;
        } => sala !== null
      ), // Garante que os null sejam removidos
  ];
  
  const fetchItemData = async (
    agendaRecepcaoActions: any,
    setTableAgenda: React.Dispatch<React.SetStateAction<AgendaItem[]>>
  ) => {
    let ItemData: AgendaItem[];
    try {

console.log(sentSelectedDate.toISOString()); // Agora retornará a data correta
  
    ItemData = await agendaRecepcaoActions.filld(sentSelectedDate.toISOString());

       console.log(sentSelectedDate.toISOString());
  
      setTableAgenda(ItemData);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };
  useEffect(() => {
    fetchItemData(agendaRecepcaoActions, setTableAgenda);
  }, [agendaRecepcaoActions, setTableAgenda]);

  useEffect(() => {
    const fetchItemDataAtiv = async () => {
      let ItemData: AtividadeItem[];
      ItemData = await atividadeActions.fill();
      setTableAtividade(ItemData);
    };
    fetchItemDataAtiv();
  }, [atividadeActions]);

  const customStyles: Modal.Styles = {
    content: {
      position: "absolute",
      top: "30%",
      left: "50%",
      transform: "translate(-50%, -20%)",
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "8px",
      maxWidth: "800px", // Ajuste a largura máxima conforme necessário
      width: "70%", // Usando uma porcentagem para a largura
      maxHeight: "70vh", // Ajuste a altura máxima conforme necessário
      overflowY: "auto",
    },
    overlay: {
      zIndex: 999, // Certifique-se de que o modal fique acima de outros elementos
    },
  };

  // Defina um conjunto de cores para cada situação
  const situationColors: { [key: string]: string } = {
    Agendado: "#FAFAD2", // GELO
    Presente: "#4CAF50", // VERDE
    Falta: "#FF5252", // VERMELHO
    "Sai após este atendimento": "#FFEB3B", // AMARELO
    Avaliação: "#FFA726", // LARANJA
    Devolutiva: "#D3D3D3", // CINZA
    "Troca de horário ou terapeuta": "#0D47A1", // AZUL ESCURO
    Encaixe: "#BA68C8", // LILAS
    "Desmarcado pela clínica": "#FF4081", // ROSA
    "Controle Interno": "#64B5F6", // Azul CLARO
  };

  const events: RoomEvent[] = tableAgenda.map((agendaItem: AgendaItem) => {
    const [yearStr, monthStr, dayStr] = agendaItem.dt_evento.split("-");
    const [hourStartStr, minuteStartStr] = agendaItem.hr_inicio.split(":");
    const [hourEndStr, minuteEndStr] = agendaItem.hr_fim.split(":");

    // Convertendo strings para números usando a função parseInt
    const year = parseInt(yearStr, 10);
    const month = parseInt(monthStr, 10) - 1; // Subtraindo 1 para corresponder ao formato JavaScript
    const day = parseInt(dayStr, 10);
    const hourStart = parseInt(hourStartStr, 10);
    const minuteStart = parseInt(minuteStartStr, 10);
    const hourEnd = parseInt(hourEndStr, 10);
    const minuteEnd = parseInt(minuteEndStr, 10);

    const start = new Date(year, month, day, hourStart, minuteStart);
    const end = new Date(year, month, day, hourEnd, minuteEnd);
    const color = situationColors[agendaItem.situacao] || "#2196f3"; // Padrão: preto se a situação não estiver definida nas cores

    return {
      title: `${agendaItem.nome_paciente}`,
      start: start,
      end: end,
      resourceId: `${agendaItem.n_sala}`,
      idAgenda: `${agendaItem.id_agenda}`,
      idPac: `${agendaItem.id_pac}`,
      idTerap: `${agendaItem.id_terap}`,
      nomeTerap: `${agendaItem.nome_terapeuta}`,

      convenio: `${agendaItem.convenio}`,
      codconvenio: `${agendaItem.codconvenio}`,
      observacao: `${agendaItem.observacao}`,
      situacao: `${agendaItem.situacao}`,
      color, // Adicione a cor ao evento
    };
  });

  // Filtrar os eventos que estão dentro do intervalo de minTime e maxTime
  const filteredEvents = events.filter((event) => {
    // Obtendo os horários dos eventos
    const eventStartHour = event.start.getHours();
    const eventStartMinute = event.start.getMinutes();
    const eventEndHour = event.end.getHours();
    const eventEndMinute = event.end.getMinutes();

    // Comparando apenas os horários dos eventos com os horários de minTime e maxTime
    return (
      (eventStartHour > minTimeHour ||
        (eventStartHour === minTimeHour &&
          eventStartMinute >= minTimeMinute)) &&
      (eventEndHour < maxTimeHour ||
        (eventEndHour === maxTimeHour && eventEndMinute <= maxTimeMinute))
    );
  });

  // Função de renderização personalizada para o evento
  const EventComponent: React.FC<any> = ({ event }) => (
    <div className="rbc-event-label" >
      <strong>{event.title.toUpperCase()}</strong>
      <div>{event.convenio.toUpperCase()}</div>
      <div
        style={{
          color: "red", // Define a cor do texto
          overflowWrap: "break-word", // Quebra o texto se for muito longo
          whiteSpace: "normal", // Permite quebra de linha automática
          wordWrap: "break-word", // Quebra o texto se for muito longo
        }}
      >
        {event.observacao.toUpperCase()}
      </div>
    </div>
  );

  const handleEventClick = (event: any) => {
    if ("resourceId" in event) {
      setSelectedEvent(event);
      // Preencher os campos do modal de edição com as informações do evento selecionado
      setIdAgenda(event.idAgenda);
      setSelectedPaciente(event.idPac); // Exemplo: preenchendo o campo do paciente com o idPac do evento

      const paciente = tablePac.find(
        (p) => p.id_pac.toString() === event.idPac
      );

      if (paciente) {
        // Filtrar os convênios disponíveis baseados nos convênios selecionados do paciente
        const conveniosSelecionados: ConvenioSelecionado[] =
          paciente.convenios_selecionados || [];

        const conveniosFiltrados = conveniosDisponiveis.filter((convenio) =>
          conveniosSelecionados.some(
            (c: ConvenioSelecionado) => c.codconvenio === convenio.codconvenio
          )
        );

        setConveniosPaciente(conveniosFiltrados);

        // Verificar se há apenas um convênio e selecioná-lo automaticamente
        if (conveniosFiltrados.length === 1) {
          setSelectedConvenio(conveniosFiltrados[0].codconvenio);
        }
      } else {
        setConveniosPaciente([]);
        setSelectedConvenio(""); // Reseta o convênio selecionado se não houver paciente
      }

      setSelectedConvenio(event.codconvenio);
      console.log(event.idTerap);
      console.log("idterap");
      
      //setSelectedTerapeuta(event.idTerap); // Exemplo: preenchendo o campo do terapeuta com o idTerap do evento
      setSelectedSala(event.resourceId);
      // Exemplo: preenchendo o campo da sala com o resourceId do evento
      setEventDate(moment(event.start).format("YYYY-MM-DD")); // Exemplo: preenchendo o campo de data com a data de início do evento formatada
      setEventStartTime(moment(event.start).format("HH:mm")); // Exemplo: preenchendo o campo de hora inicial com a hora de início do evento formatada
      setEventEndTime(moment(event.end).format("HH:mm"));
      setEventSituation(event.situacao);
      setObservacao(event.observacao);

      if (event.resourceId && sentSelectedDate) {
        console.log(sentSelectedDate);


      const dayOfWeek = getDayOfWeek(sentSelectedDate); // Obter o dia da semana baseado na data selecionada

console.log(dayOfWeek);


        
        const sala = tableSala.find((sala) => sala.n_sala === event.resourceId);
console.log("sala");
console.log(sala);
 const selectedTurno = localStorage.getItem("selectedAgenda"); // Obter o turno do localStorage
    console.log("Turno do localStorage:", selectedTurno);

        
        if (sala && sala.turnos[dayOfWeek]) {
          const turno = sala.turnos[dayOfWeek];
          // Preenche os campos com os dados obtidos do turno
console.log("turno");
console.log(turno);

          if (turno) {
            if (selectedTurno==="tarde"){
            setSelectedTerapeuta(turno.tarde.id_terap);
            setNomeTerap( turno.tarde.terapeuta);
            setAtividade(turno.tarde.atividade);
            setTmpAtividade(turno.tarde.tmp_atividade );
            }
            else{
           setSelectedTerapeuta(turno.manha.id_terap);
            setNomeTerap(turno.manha.terapeuta);
            setAtividade(turno.manha.atividade);
            setTmpAtividade(turno.manha.tmp_atividade );
            }
            }
        } else {
          // Limpa os campos se não houver dados para o dia da semana
          setSelectedTerapeuta("");
          setAtividade("");
          setTmpAtividade("");
          setNomeTerap("");
        }
      }

      setShowEditModal(true);
    }
  };

  const handleEditEvent = async () => {
    if (
      idUser &&
      eventDate &&
      eventStartTime &&
      tmpAtividade &&
      selectedSala !== null &&
      selectedTerapeuta
    ) {
      console.log("Iniciando edição de evento");

      // Calcule o horário de término
      const startDateTime = moment(eventStartTime, "HH:mm");
      const duration = moment.duration(tmpAtividade);

      let endTimeFormatted: string;
      const endDateTime = startDateTime.clone().add(duration);
      endTimeFormatted = endDateTime.format("HH:mm");
      const formattedDate = moment(eventDate).format("YYYY-MM-DD");

      const eventData = {
        id_agenda: idAgenda,
        id_pac: selectedPaciente,
        n_sala: selectedSala,
        codconvenio: selectedConvenio,
        codproc: codProc || null,
        dt_evento: formattedDate,
        hr_inicio: eventStartTime,
        hr_fim: endTimeFormatted,
        id_terap: selectedTerapeuta,
        situacao: eventSituation,
        id_user: idUser,
        observacao: observacao || "",
      };

      try {
        // Chamada à API para editar a agenda
        const success = await agendaRecepcaoActions.edit(eventData);

        if (success) {
          // Se for bem-sucedido
          setMessage("Dados atualizados com sucesso.");
          setShowMessage(true);
          setShowIcon(false);
          fetchItemData(agendaRecepcaoActions, setTableAgenda);

          // Salve as posições de rolagem horizontal e vertical antes de recarregar a página
          sessionStorage.setItem("scrollX", window.scrollX.toString());
          sessionStorage.setItem("scrollY", window.scrollY.toString());
        } else {
          // Se a API não forneceu mais detalhes
          setMessage("Erro ao processar a solicitação.");
          setShowMessage(true);
          setShowIcon(true);
        }
      } catch (error: any) {
        console.log(error);

        if (error.response) {
          const errorMessage =
            error.response.data?.error || "Erro ao processar a solicitação.";
          setMessage(errorMessage);
        } else {
          setMessage(
            "Ocorreu um erro inesperado ao tentar atualizar os dados."
          );
        }
        setShowMessage(true);
        setShowIcon(true);
      }
    } else {
      setMessage("Por favor, preencha todos os campos.");
      setShowIcon(true);
    }
  };

  const handleCloseMessage = () => {
    setShowEditModal(false);
    setShowMessage(false);
  };

  const handleCloseModal = () => {
    // Limpe os campos do formulário aqui
    setSelectedPaciente("");
    setSelectedSala(null);
    setSelectedTerapeuta("");
    setEventDate(null);
    setEventStartTime("");
    setEventEndTime("");
    setCodproc("");
    setTmpAtividade("");

    setShowEditModal(false);
  };

  const onNavigate = useCallback(
    (newDate: Date) => setSentSelectedDate(newDate),
    [setSentSelectedDate]
  );
  const onView = useCallback(
    (newView: CalendarView) => setView(newView),
    [setView]
  );

  function getDayOfWeek(date: Date): string {
    const daysOfWeek = [
      "Domingo",
      "Segunda-Feira",
      "Terça-Feira",
      "Quarta-Feira",
      "Quinta-Feira",
      "Sexta-Feira",
      "Sábado",
    ];
    return daysOfWeek[date.getDay()];
  }

  function handleSalaChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const selectedSala = e.target.value;
    setSelectedSala(selectedSala);

    if (selectedSala && sentSelectedDate) {
      const dayOfWeek = getDayOfWeek(sentSelectedDate); // Obter o dia da semana baseado na data selecionada
      const sala = tableSala.find((sala) => sala.n_sala === selectedSala);

      if (sala && sala.turnos[dayOfWeek]) {
        const turno = sala.turnos[dayOfWeek];

        // Preenche os campos com os dados obtidos do turno
        if (turno) {
          setSelectedTerapeuta(
            turno.manha.id_terap || turno.tarde.id_terap || ""
          );
          setNomeTerap(   turno.manha.terapeuta || turno.tarde.terapeuta || ""
        );
          setAtividade(turno.manha.atividade || turno.tarde.atividade || "");
          setTmpAtividade(
            turno.manha.tmp_atividade || turno.tarde.tmp_atividade || ""
          );
        }
      } else {
        // Limpa os campos se não houver dados para o dia da semana
        setSelectedTerapeuta("");
        setNomeTerap("");
        setAtividade("");
        setTmpAtividade("");
      }
    }
  }

  useEffect(() => {
    const slots = document.querySelectorAll(".rbc-time-slot");
    slots.forEach((slot) => {
      slot.setAttribute("role", "button");
    });
  }, []);

 const handlePacienteChange = (option: { value: string; label: string } | null) => {
  const pacienteId = option ? option.value : ""; // Pega o ID do paciente ou reseta para vazio
  setSelectedPaciente(pacienteId);

  if (pacienteId) {
    // Encontrar o paciente selecionado
    const paciente = tablePac.find((p) => p.id_pac.toString() === pacienteId);

    if (paciente) {
      // Filtrar os convênios disponíveis baseados nos convênios selecionados do paciente
      const conveniosSelecionados: ConvenioSelecionado[] =
        paciente.convenios_selecionados || [];

      const conveniosFiltrados = conveniosDisponiveis.filter((convenio) =>
        conveniosSelecionados.some(
          (c: ConvenioSelecionado) => c.codconvenio === convenio.codconvenio
        )
      );

      setConveniosPaciente(conveniosFiltrados);

      // Verificar se há apenas um convênio e selecioná-lo automaticamente
      if (conveniosFiltrados.length === 1) {
        setSelectedConvenio(conveniosFiltrados[0].codconvenio);
      }
    } else {
      setConveniosPaciente([]);
      setSelectedConvenio(""); // Reseta o convênio selecionado se não houver paciente
    }
  } else {
    setConveniosPaciente([]);
    setSelectedConvenio(""); // Reseta tudo se nenhum paciente estiver selecionado
  }
};


  const handleDeleteButtonClick = (idagenda: string) => {
    setDeleteItem(idAgenda);
    setConfirmDelete(true);
  };

  const confirmDeleteItem = async () => {
    if (deleteItem && idUser && IsAdministrador) {
      try {
        // Aguarda a conclusão da exclusão
        await agendaRecepcaoActions.delete(String(idAgenda), idUser);

        // Fecha o modal e reseta os estados após a exclusão ser bem-sucedida
        setDeleteItem(null);
        setConfirmDelete(false); // Aqui fechamos o modal

        // Salva a posição de rolagem antes de atualizar os dados
        sessionStorage.setItem("scrollX", window.scrollX.toString());
        sessionStorage.setItem("scrollY", window.scrollY.toString());

        // Atualiza os dados da agenda sem recarregar a página
        fetchItemData(agendaRecepcaoActions, setTableAgenda);
      } catch (error) {
        console.error("Erro ao deletar o item:", error);
      }
    } else {
      alert(
        "Você não possui permissão. Caso necessite realizar a operação, entre em contato com o administrador para alterar as permissões."
      );
    }
  };

  const cancelDeleteItem = () => {
    setDeleteItem(null);
    setConfirmDelete(false);
  };



const exportFullCalendarToPDF = async () => {
  const calendarElement = document.getElementById("calendariogeral");
  if (!calendarElement) {
    alert("Elemento do calendário não encontrado!");
    return;
  }

  // Forçar o tamanho total do elemento para capturar tudo
  const originalOverflow = document.body.style.overflow;
  document.body.style.overflow = "hidden"; // Previne rolagens desnecessárias

  // Capturar o elemento completo
  const canvas = await html2canvas(calendarElement, {
    scale: 2, // Aumenta a resolução da imagem
    useCORS: true,
    allowTaint: true,
    scrollX: 0, // Resolve o scroll horizontal
    scrollY: 0, // Resolve o scroll vertical
  });

  document.body.style.overflow = originalOverflow; // Restaurar overflow

  const imageData = canvas.toDataURL("image/png");
  const pdf = new jsPDF({
    orientation: "landscape", // Horizontal
    unit: "mm",
    format: "a4",
  });

  const pageWidth = 297; // Largura da página em mm (A4 Landscape)
  const pageHeight = 210; // Altura da página em mm
  const pxPerMm = canvas.width / pageWidth; // Proporção entre pixels e mm

  const imgWidth = canvas.width / pxPerMm; // Largura total da imagem em mm
  const imgHeight = canvas.height / pxPerMm; // Altura total da imagem em mm

  const totalPagesHorizontally = Math.ceil(imgWidth / pageWidth);
  const totalPagesVertically = Math.ceil(imgHeight / pageHeight);

  for (let y = 0; y < totalPagesVertically; y++) {
    for (let x = 0; x < totalPagesHorizontally; x++) {
      const xOffset = x * pageWidth * pxPerMm; // Deslocamento horizontal em px
      const yOffset = y * pageHeight * pxPerMm; // Deslocamento vertical em px

      const partialCanvas = document.createElement("canvas");
      partialCanvas.width = pageWidth * pxPerMm; // Largura do fragmento em px
      partialCanvas.height = pageHeight * pxPerMm; // Altura do fragmento em px

      const partialContext = partialCanvas.getContext("2d");
      partialContext?.drawImage(
        canvas,
        xOffset, // Posição horizontal na imagem original
        yOffset, // Posição vertical na imagem original
        pageWidth * pxPerMm, // Largura do fragmento em px
        pageHeight * pxPerMm, // Altura do fragmento em px
        0,
        0,
        pageWidth * pxPerMm,
        pageHeight * pxPerMm
      );

      const partialImage = partialCanvas.toDataURL("image/png");
      pdf.addImage(partialImage, "PNG", 0, 0, pageWidth, pageHeight);

      if (x < totalPagesHorizontally - 1 || y < totalPagesVertically - 1) {
        pdf.addPage();
      }
    }
  }

  // Salvar o PDF
  pdf.save("calendario-completo.pdf");
};
   // UseEffect para "escutar" mudanças no estado tableAgenda
  useEffect(() => {
    // Log do estado após a atualização
    console.log("Tabela da agenda atualizada no calendar:", tableAgenda);
  }, [tableAgenda]); // Esse efeito será disparado sempre que tableAgenda mudar

 useEffect(() => {
  const datePickerMonth = document.querySelector('.react-datepicker__month');
  if (datePickerMonth instanceof HTMLElement) {
    datePickerMonth.style.zIndex = '90000';  // Ajuste o valor do z-index conforme necessário
  }
}, []);

  
  return (
    <div id="calendariogeral" className="w-full m-0 p-0" style={{ margin: 0, padding: 0 }}>
      <StyledCalendar 
        events={filteredEvents}
        onNavigate={onNavigate}
        onView={onView}
        views={["day", "week", "month", "agenda"]}
        view={view}
        eventPropGetter={(event) => ({
          style: {
            backgroundColor: (event as RoomEvent).color,
            opacity: 0.9, // Adjust this value to control transparency
          },
        })}
        step={30}
        showMultiDayTimes
        date={sentSelectedDate}
        localizer={localizer}
        resources={rooms}
        resourceIdAccessor={(resource: any) => resource.id}
        resourceTitleAccessor={(resource: any) => resource.title}
        selectable={true}
        onSelectEvent={handleEventClick}
        onSelectSlot={handleEventClick}
        defaultView="day"
        min={minTime}
        max={maxTime}
        timeslots={1}
        toolbar={false}
        messages={{
          today: "Hoje",
          next: "Próximo",
          previous: "Anterior",
          day: "Dia",
          week: "Semana",
        }}
        formats={{
          eventTimeRangeFormat: () => "",
          timeGutterFormat: "HH:mm",
        }}
        components={{
          resourceHeader: CustomResourceHeader as unknown as undefined,
          event: EventComponent,
        }}
      />
      
      <Modal
        isOpen={showEditModal}
        onRequestClose={handleCloseModal}
        style={customStyles}
      >
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          {showMessage && (
            <MessageBox
              message={message}
              onClose={() => {
                setShowIcon(false);
                handleCloseMessage();
              }}
              icon={
                showIcon ? (
                  <ExclamationCircleIcon className="w-10 h-10 mx-auto text-red-500" />
                ) : null
              }
            />
          )}
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              Editar Agendamento -{" "}
              {moment(sentSelectedDate).format("dddd, D [de] MMMM [de] YYYY")}
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={handleCloseModal} // Adicionando o handleCloseModal aqui
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <form className="p-4 md:p-5">
            <div className="grid gap-4 mb-4 grid-cols-2">
              {/* Select da Sala */}
              <div>
                <label
                  htmlFor="sala"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Sala
                </label>
                <select
                  id="sala"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  value={selectedSala ?? ""}
                  onChange={handleSalaChange}
                  //onChange={(e) => setSelectedSala(e.target.value)}
                >
                  <option value="">Selecione a Sala</option>
                  {tableSala.map((sala) => (
                    <option key={sala.n_sala} value={sala.n_sala}>
                      {sala.n_sala}
                    </option>
                  ))}
                </select>
              </div>
              {/* Select do Paciente */}
              <div>
                <label
                  htmlFor="paciente"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Paciente
                </label>
<Select
  options={tablePac.map((paciente) => ({
    value: paciente.id_pac.toString(),
    label: paciente.nome,
  }))}
  value={
    tablePac
      .map((paciente) => ({
        value: paciente.id_pac.toString(),
        label: paciente.nome,
      }))
      .find((option) => option.value === selectedPaciente) || null
  }
  onChange={(option) => handlePacienteChange(option)}
  placeholder="Selecione o Paciente"
  isClearable
  classNamePrefix="react-select"
/>              </div>
              {/* Select do Plano de Saúde */}
              <div>
                <label
                  htmlFor="convenio"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Plano de Saúde
                </label>
                <select
                  id="convenio"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  value={selectedConvenio}
                  onChange={(e) => setSelectedConvenio(e.target.value)}
                >
                  <option value="">Selecione o Plano de Saúde</option>
                  {conveniosPaciente.map((convenio) => (
                    <option
                      key={convenio.codconvenio}
                      value={convenio.codconvenio}
                    >
                      {convenio.convenio}
                    </option>
                  ))}
                </select>
              </div>

              {/* Select do Terapeuta */}
              <div>
                <label
                  htmlFor="terapeuta"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Terapeuta
                </label>
            <label>{nomeTerap}</label>
    </div>
              {/* Campo de Hora Inicial */}
              <div>
                <label
                  htmlFor="start-time"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Hora Inicial
                </label>
                <input
                  type="time"
                  id="start-time"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  value={eventStartTime}
                  onChange={(e) => setEventStartTime(e.target.value)}
                />
              </div>
              {selectedSala === "0" ? (
                <div>
                  <label
                    htmlFor="start-end"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Hora Final
                  </label>
                  <input
                    type="time"
                    id="start-end"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    value={eventEndTime}
                    onChange={(e) => setEventEndTime(e.target.value)}
                  />
                </div>
              ) : (
                ""
              )}
              {selectedSala !== "0" ? (
                <div className="mt-2">
                  <span className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                    Tempo de Atividade: {tmpAtividade}
                  </span>
                  <span className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Atividade: {atividade}
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-span-2">
              <label
                htmlFor="observacao"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Observação
              </label>
              <input
                type="text"
                id="observacao"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                value={observacao}
                onChange={(e) => setObservacao(e.target.value)}
              />
            </div>
            <div className="col-span-2">
              <label
                htmlFor="situation"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Situação
              </label>
       <select
  id="situation"
  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
value={eventSituation || "Agendado"}
  onChange={(e) => setEventSituation(e.target.value)}
>
  {/* Opções para as situações */}
  <option value="Agendado" disabled={!(auth.user?.admin ?? 0)}>
    Agendado
  </option>
  <option value="Presente">Presente</option>
  <option value="Falta">
    Falta
  </option>
  <option value="Sai após este atendimento" disabled={!(auth.user?.admin ?? 0)}>
    Sai após este atendimento
  </option>
  <option value="Avaliação" disabled={!(auth.user?.admin ?? 0)}>
    Avaliação
  </option>
  <option value="Devolutiva" >
    Devolutiva
  </option>
  <option value="Troca de horário ou terapeuta" disabled={!(auth.user?.admin ?? 0)}>
    Troca de horário ou terapeuta
  </option>
  <option value="Encaixe">Encaixe</option>
  <option value="Desmarcado pela clínica" disabled={!(auth.user?.admin ?? 0)}>
    Desmarcado pela clínica
  </option>
  <option value="Controle Interno" disabled={!(auth.user?.admin ?? 0)}>
    Controle Interno
  </option>
</select>

            </div>

            <div className="flex justify-end gap-4 mt-4">
              <button
                type="button"
                onClick={handleEditEvent}
                className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Concluir
              </button>

              {IsAdministrador === true && (
                <Tooltip title="Deletar" placement="top">
                  <Button
                    placeholder=""
                    color="blue-gray"
                    className="w-8 h-8 flex items-center justify-center p-1 bg-red-500 hover:bg-red-700 text-white rounded-full"
                    onClick={() => handleDeleteButtonClick(idAgenda)}
                  >
                    <TrashIcon className="w-6 h-6" />
                  </Button>
                </Tooltip>
              )}
            </div>

            {/* Janela de confirmação */}
            {confirmDelete && deleteItem && (
              <div className="fixed inset-0 z-10 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                <div className="relative w-auto max-w-3xl mx-auto my-6">
                  <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                    <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blue-gray-200">
                      <h3 className="text-3xl font-semibold">
                        Confirmar exclusão
                      </h3>
                      <button
                        className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={cancelDeleteItem} // Fecha o modal
                      >
                        <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button>
                    </div>
                    <div className="relative p-6 flex-auto">
                      <p className="my-4 text-blue-gray-500 text-lg leading-relaxed">
                        Tem certeza que deseja excluir o Agendamento?
                      </p>
                    </div>
                    <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blue-gray-200">
                      <button
                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={cancelDeleteItem} // Cancela e fecha o modal
                      >
                        Cancelar
                      </button>
                      <button
                        className="bg-red-700 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={confirmDeleteItem} // Confirma a exclusão
                      >
                        Confirmar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default CustomCalendar;
