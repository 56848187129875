import { Button, Card, CardFooter, Typography } from "@material-tailwind/react";
import { useContext, useEffect, useState } from "react";
import { GeralContext } from "../../../../contexts/Geral/GeralContext";
import { useNavigate } from "react-router-dom";
import {
  MagnifyingGlassIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Tooltip } from "@mui/material";
import { AuthContext } from "../../../../contexts/Auth/AuthContext";
import { PessoaContext } from "../../../../contexts/Pessoa/PessoaContext";
import DetailModal from "../../../../components/Utils/DetailModal";

const TABLE_HEAD = ["N. Sala", "Alocação", "Ação"];

const ITEMS_PER_PAGE = 20; // Set the number of items per page

interface Turno {
  terapeuta: string;
  monitor: string;
  atividade: string;
}

interface SalaItem {
  id_sala: string;
  n_sala: string;
  turnos: {
    [key: string]: {
      manha: Turno;
      tarde: Turno;
    };
  };
}

interface DeleteItemParams {
  id_sala: string;
  n_sala: string;
}

interface TerapeutaItem {
  id_terap: string;
  nome: string;
  especialidade: string;
}

interface AtividadeItem {
  id_ativ: string;
  atividade: string;
}

interface MonitorItem {
  id_monitor: string;
  nome: string;
  especialidade: string;
}

export function GerenciaSala() {
  const { salaActions } = useContext(GeralContext);
  const { terapActions, monitorActions } = useContext(PessoaContext);
  const [tableData, setTableData] = useState<SalaItem[]>([]);
  const [terapeutas, setTerapeutas] = useState<TerapeutaItem[]>([]);
  const [monitor, setMonitor] = useState<MonitorItem[]>([]);
  const [atividades, setAtividades] = useState<AtividadeItem[]>([]);
  const [salaFilter, setSalaFilter] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<DeleteItemParams | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [, setSelectedItem] = useState<SalaItem | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<{ [key: string]: any }>({});
  const auth = useContext(AuthContext);
  const idUser = auth.user?.id_user;
  const IsAdministrador = !!(
    auth.user?.ocupacao === "Administrador" || auth.user?.admin
  );
  const IsAdministradorSupervisor = !!(
    auth.user?.ocupacao === "Administrador" ||
    auth.user?.admin ||
    auth.user?.supervisor
  );
  const IsTerapeuta = !!(auth.user?.ocupacao === "Terapeuta");
  const IsMonitor = !!(auth.user?.ocupacao === "Monitor");
  const IsAdministrativo = !!(auth.user?.ocupacao === "Administrativo");
  const AdministrativoPermissaoGeral = (auth.user?.permissao ?? 0) >= 3;

  useEffect(() => {
    const fetchItemData = async () => {
      setIsLoading(true);
      try {
        const [ItemData, terapeutasData, monitorData, atividadesData] =
          await Promise.all([
            salaActions.fill(),
            terapActions.fill(),
            monitorActions.fill(),
            salaActions.fill(),
          ]);
        setTableData(ItemData);
        setTerapeutas(terapeutasData);
        setMonitor(monitorData);
        setAtividades(atividadesData);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchItemData();
  }, [salaActions, terapActions, monitorActions]);

  useEffect(() => {
    if (salaFilter !== "") {
      setCurrentPage(1);
    }
  }, [salaFilter]);

  const filteredData = Array.isArray(tableData)
    ? tableData.filter(({ n_sala }) => {
        if (n_sala) {
          const salaMatches = salaFilter
            ? n_sala.toString().toLowerCase().includes(salaFilter.toLowerCase())
            : true;
          return salaMatches;
        }
        return true;
      })
    : [];

  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);

  const visibleData = filteredData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleDeleteButtonClick = (deleteItem: DeleteItemParams) => {
    setDeleteItem(deleteItem);
    setConfirmDelete(true);
  };

  const handleButtonClick = () => {
    navigate("/sala/add");
  };

  const handleEditButtonClick = (item: SalaItem) => {
    setSelectedItem(item);
    navigate("/sala/edit", { state: { selectedItem: item } });
  };

  const first = () => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }
  };

  const ult = () => {
    if (currentPage < totalPages) {
      setCurrentPage(totalPages);
    }
  };

  const next = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const confirmDeleteItem = () => {
    if (deleteItem && idUser && IsAdministrador && IsAdministrador === true) {
      salaActions
        .delete(String(deleteItem.id_sala), idUser)
        .then(() => {
          setDeleteItem(null);
          setConfirmDelete(false);
          window.location.reload();
        })
        .catch((error) => {
          console.error("Erro ao deletar o item:", error);
        });
    } else {
      alert(
        "Você não possui permissão, caso necessite realizar a operação entre em contato com o administrador para alterar as permissões."
      );
    }
  };

  const cancelDeleteItem = () => {
    setDeleteItem(null);
    setConfirmDelete(false);
  };

  const handleViewButtonClick = (item: SalaItem) => {
    const formattedTurnos = Object.entries(item.turnos)
      .map(([dia, periodos]) => {
        if (dia === "null") return null;

        // Manhã
        const manhaAtividade = periodos.manha.atividade
          ? `Atividade: ${periodos.manha.atividade}`
          : "";
        const manhaTerapeuta = periodos.manha.terapeuta
          ? `Terapeuta: ${periodos.manha.terapeuta}`
          : "";
        const manhaMonitor = periodos.manha.monitor
          ? `Monitor: ${periodos.manha.monitor}`
          : "";
        const manha =
          manhaAtividade || manhaTerapeuta || manhaMonitor
            ? `Manhã - ${[manhaAtividade, manhaTerapeuta, manhaMonitor]
                .filter(Boolean)
                .join(", ")}`
            : "";

        // Tarde
        const tardeAtividade = periodos.tarde.atividade
          ? `Atividade: ${periodos.tarde.atividade}`
          : "";
        const tardeTerapeuta = periodos.tarde.terapeuta
          ? `Terapeuta: ${periodos.tarde.terapeuta}`
          : "";
        const tardeMonitor = periodos.tarde.monitor
          ? `Monitor: ${periodos.tarde.monitor}`
          : "";
        const tarde =
          tardeAtividade || tardeTerapeuta || tardeMonitor
            ? `Tarde - ${[tardeAtividade, tardeTerapeuta, tardeMonitor]
                .filter(Boolean)
                .join(", ")}`
            : "";

        // Exclude days without any relevant data
        if (!manha && !tarde) return null;

        // Combine both morning and afternoon if they have values
        return `${dia}: ${[manha, tarde].filter(Boolean).join(", ")}`;
      })
      .filter(Boolean)
      .join("\n");

    setModalData({
      "Número da Sala": item.n_sala ? item.n_sala : "",
      Turnos: formattedTurnos,
    });
    setIsModalOpen(true);
  };

  return (
    <div>
      <div className="p-4 mb-4 flex flex-col md:flex-row md:items-center md:space-x-4 bg-gray-100 rounded-lg">
        <Button
          placeholder=""
          variant="outlined"
          size="sm"
          onClick={handleButtonClick}
          className={
            "p-2 my-2 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-normal rounded-lg text-sm me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          }
        >
          <PlusIcon className="w-6 h-6" />
        </Button>

        <div className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-2 my-4">
          <label htmlFor="salaFilter" className="text-black-700 md:mt-0 mt-2">
            N. Sala:
          </label>
          <div className="relative w-full md:w-auto">
            <input
              type="text"
              id="salaFilter"
              value={salaFilter}
              onChange={(e) => setSalaFilter(e.target.value)}
              className="border border-gray-300 pl-8 pr-2 py-2 rounded w-full"
            />
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-2 my-4">
          <h2 className="text-3xl font-bold font-mono relative pr-4 italic text-transparent bg-clip-text bg-gradient-to-r to-green-600 from-blue-400 mb-4 md:mb-0">
            Registros
          </h2>
          <h2 className="text-3xl font-bold font-mono relative pr-4 italic text-transparent bg-clip-text bg-gradient-to-r to-blue-600 from-green-400 mb-4 md:mb-0">
            Salas
          </h2>
        </div>
      </div>
      <Card placeholder="" className="h-full w-full overflow-auto">
        <table className="w-full min-w-max table-auto text-center">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                >
                  <Typography
                    placeholder=""
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={TABLE_HEAD.length} className="p-4">
                  Carregando...
                </td>
              </tr>
            ) : (
              visibleData.map((item, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-gray-200" : "bg-white"}
                >
                  <td className="p-4 border-b border-blue-gray-100">
                    <Typography
                      placeholder=""
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {item.n_sala || ""}
                    </Typography>
                  </td>
                  <td className="p-4 border-b border-blue-gray-100 text-left">
                    {Object.entries(item.turnos).map(
                      ([dia, periodos]) =>
                        dia !== "null" ? ( // Verifica se o dia não é null ou undefined
                          <div key={dia} className="text-left mb-2">
                            <Typography
                              placeholder=""
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              <strong>{dia}: </strong>
                              Manhã - {
                                periodos.manha.atividade
                              } (Terapeuta: {periodos.manha.terapeuta},
                              Auxiliar: {periodos.manha.monitor}), Tarde -{" "}
                              {periodos.tarde.atividade} (Terapeuta:{" "}
                              {periodos.tarde.terapeuta}, Auxiliar:{" "}
                              {periodos.tarde.monitor})
                            </Typography>
                          </div>
                        ) : null // Se dia for null ou undefined, não renderiza nada
                    )}
                  </td>
                  <td className="border-b border-blue-gray-100 p-2">
                    <div className="flex space-x-2 justify-center">
                      <Tooltip title="Visualizar">
                        <Button
                          placeholder=""
                          className="w-8 h-8 flex items-center justify-center p-1 bg-blue-500 hover:bg-blue-700 text-white rounded-full"
                          onClick={() => handleViewButtonClick(item)}
                        >
                          <MagnifyingGlassIcon className="h-5 w-5" />
                        </Button>
                      </Tooltip>
                      {AdministrativoPermissaoGeral ||
                      IsAdministradorSupervisor ? (
                        <Tooltip title="Atualizar Informações" placement="top">
                          <Typography
                            placeholder=""
                            variant="small"
                            as="button"
                            onClick={() => handleEditButtonClick(item)}
                            color="blue-gray"
                            className="w-8 h-8 flex items-center justify-center p-1 bg-green-500 hover:bg-green-700 text-white rounded-full"
                          >
                            <PencilIcon className="w-6 h-6" />
                          </Typography>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      {IsAdministrador === true ? (
                        <Tooltip title="Deletar" placement="top">
                          <Button
                            placeholder=""
                            color="blue-gray"
                            className="w-8 h-8 flex items-center justify-center p-1 bg-red-500 hover:bg-red-700 text-white rounded-full"
                            onClick={() => handleDeleteButtonClick(item)}
                          >
                            <TrashIcon className="w-6 h-6" />
                          </Button>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        {confirmDelete && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-4 rounded-md">
              <Typography placeholder="" variant="h6" className="mb-4">
                Tem certeza que deseja excluir a sala {deleteItem?.n_sala}?
              </Typography>
              <div className="flex justify-end space-x-2">
                <Button
                  placeholder=""
                  variant="outlined"
                  color="red"
                  onClick={confirmDeleteItem}
                >
                  Confirmar
                </Button>
                <Button
                  placeholder=""
                  variant="outlined"
                  onClick={cancelDeleteItem}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </div>
        )}
      </Card>
      <CardFooter
        placeholder=""
        className="flex items-center justify-between border-t border-blue-gray-50 p-4"
      >
        <Typography
          placeholder=""
          variant="small"
          color="blue-gray"
          className="font-normal"
        >
          Página {currentPage} de {totalPages}
        </Typography>
        <div className="flex gap-2">
          <Button placeholder="" onClick={first} variant="outlined" size="sm">
            Primeira
          </Button>
          <Button placeholder="" onClick={prev} variant="outlined" size="sm">
            Anterior
          </Button>
          <Button placeholder="" onClick={next} variant="outlined" size="sm">
            Próxima
          </Button>
          <Button placeholder="" onClick={ult} variant="outlined" size="sm">
            Última
          </Button>
        </div>
      </CardFooter>
      <DetailModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        data={modalData}
      />
    </div>
  );
}
