import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AgendaManha } from "./Manha";
import { AgendaTarde } from "./Tarde";
import Legend from "../../components/Utils/Legend";
import moment from "moment";
import { pt } from "date-fns/locale";  // Ajuste para importar o locale correto

const Agenda: React.FC = () => {
  const [selectedAgenda, setSelectedAgenda] = useState<"manha" | "tarde">(() => {
    return (
      (localStorage.getItem("selectedAgenda") as "manha" | "tarde") || "manha"
    );
  });

  const [selectedDate, setSelectedDate] = useState(() => {
    const storedDate = localStorage.getItem("selectedDateManha");
    return storedDate ? new Date(storedDate) : new Date();
  });


  
  const [refreshKey, setRefreshKey] = useState(0);

  // Atualiza o localStorage quando a data ou agenda mudam
  useEffect(() => {
    localStorage.setItem("selectedAgenda", selectedAgenda);
    updateLocalStorageDate(selectedDate);
  }, [selectedAgenda, selectedDate]);

  const updateLocalStorageDate = (date: Date) => {
    localStorage.setItem("selectedDateManha", date.toISOString());
    localStorage.setItem("selectedDateTarde", date.toISOString());
  };

  const handlePreviousDate = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(selectedDate.getDate() - 1);
    setSelectedDate(newDate);
    updateLocalStorageDate(newDate);
    setRefreshKey((prev) => prev + 1);
  };

  const handleNextDate = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(selectedDate.getDate() + 1);
    setSelectedDate(newDate);
    updateLocalStorageDate(newDate);
    setRefreshKey((prev) => prev + 1);
  };

const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  const selectedDateString = e.target.value; // Obtém o valor da data como string no formato YYYY-MM-DD
  const selectedDateParts = selectedDateString.split("-"); // Divide a string em partes (ano, mês, dia)
  
  const year = parseInt(selectedDateParts[0]);
  const month = parseInt(selectedDateParts[1]) - 1; // Mês base zero (0-11)
  const day = parseInt(selectedDateParts[2]);
  
  // Cria a nova data no fuso horário local
  const newDate = new Date(year, month, day);

  // Ajuste para UTC (se necessário, conforme sua lógica de fusos horários)
  // newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset()); // Ajusta para UTC, se precisar

  setSelectedDate(newDate); // Atualiza o estado com a nova data
  updateLocalStorageDate(newDate); // Salva no localStorage
  setRefreshKey((prev) => prev + 1); // Força recriação, se necessário
};


  
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      {/* Área de seleção Manhã/Tarde */}
      <nav className="flex justify-center items-center space-x-2 p-3 bg-gray-200">
        <button
          onClick={() => setSelectedAgenda("manha")}
          className={`px-4 py-2 rounded ${
            selectedAgenda === "manha"
              ? "bg-blue-500 text-white"
              : "bg-gray-200 text-gray-700"
          }`}
        >
          Manhã
        </button>
        <div className="flex items-center space-x-4">
          <button
            onClick={() => setSelectedAgenda("tarde")}
            className={`px-4 py-2 rounded ${
              selectedAgenda === "tarde"
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
          >
            Tarde
          </button>
          <Legend />
        </div>
      </nav>

      {/* Controle de data */}
      <div className="flex flex-col items-center mx-4">
        <div className="text-center mb-4">
          <h4 className="font-bold">
            {moment(selectedDate).format("dddd, D [de] MMMM [de] YYYY")}
          </h4>
        </div>
        <div className="flex items-center">
          <button
            onClick={handlePreviousDate}
            className="px-4 py-2 bg-blue-500 text-white rounded-l-lg hover:bg-blue-600"
          >
            Anterior
          </button>
          <input
            type="date"
            value={moment(selectedDate).format("YYYY-MM-DD")}
            onChange={handleDateChange}
            className="px-4 py-2 mx-2 text-center border rounded-md"
          />
          
          <button
            onClick={handleNextDate}
            className="px-4 py-2 bg-blue-500 text-white rounded-r-lg hover:bg-blue-600"
          >
            Próximo
          </button>
        </div>
      </div>
   {/* Área da agenda */}
      <div key={refreshKey} className="flex-1 bg-white p-3 rounded shadow-md">
        {selectedAgenda === "manha" && <AgendaManha />}
        {selectedAgenda === "tarde" && <AgendaTarde />}
      </div>
    </div>
  );
};

export default Agenda;
