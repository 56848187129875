import { useContext } from "react";
import { Login } from "../../pages/Login";
import { AuthContext } from "./AuthContext";

export const RequireAuth = ({
  children,
  terapeuta = 1,
  monitor = 1,
  administrativo = 1,
}: {
  children: JSX.Element;
  terapeuta?: number;
  monitor?: number;
  administrativo?: number;
}) => {
  const auth = useContext(AuthContext);

  if (!auth.user) {
    // Se não houver um usuário autenticado, redireciona para a página de login
    return <Login />;
  }

  // Verifica se o usuário tem permissão para acessar com base em seu nível
  const IsAdministradorSupervisor =
    auth.user?.ocupacao === "Administrador" ||
    auth.user?.admin ||
    auth.user?.supervisor;
  const IsTerapeuta = auth.user.ocupacao === "Terapeuta";
  const IsMonitor = auth.user.ocupacao === "Monitor";
  const IsAdministrativo = auth.user.ocupacao === "Administrativo";
  const AdministrativoPermissao = auth.user?.permissao;

  // Admins e supervisores podem acessar qualquer página
  if (IsAdministradorSupervisor) {
    return children;
  }

  // Lógica de restrição baseada no nível do usuário
  const isBlocked =
    (terapeuta === 0 && IsTerapeuta) ||
    (monitor === 0 && IsMonitor) ||
    ((administrativo === 0 || AdministrativoPermissao < administrativo) &&
      IsAdministrativo);

  // Se o acesso não estiver bloqueado para o usuário, permite o acesso
  if (!isBlocked) {
    return children;
  }

  // Se o acesso estiver bloqueado para o usuário, redireciona para a página de login
  return <Login />;
};
