import * as pdfMake from "pdfmake/build/pdfmake";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import logo from "../../images/logo/logo.png";

// Importe os arquivos de fonte do pdfmake
import pdfFonts from "pdfmake/build/vfs_fonts";

// Crie um novo objeto vfs com os arquivos de fonte
const newVfs = pdfFonts.vfs;

// Atribua o novo objeto vfs à propriedade pdfMake.vfs
(pdfMake as any).vfs = newVfs;

interface Paciente {
  id_seq: string;
  nome: string;
  dt_nasc: string;
  genero: string;
  logradouro: string;
  numeral: string;
  bairro: string;
  cidade: string;
  estado: string;
  cep: string;
  complemento: string;
  convenio: string;
  n_cartao: string;
  sessoes: number;
  periodo: string;
  dias?: number;
  med_resp: string;
  crm: string;
  tel_med: string;
  dt_insert: string;
  cid_geral: string;
  diag_geral: string;
  cid_fisio: string;
  diag_fisio: string;
  cid_fono: string;
  diag_fono: string;
  cid_psico: string;
  diag_psico: string;
  responsaveis?: Responsavel[];
  historico?: Historico[];
}

interface Historico {
  id_pac: string;
  historico_medico: string;
  alergias: string;
  medicamentos_em_uso: string;
  motivo: string;
  historico_procedimentos: string;
  id_terap: string;
  nome_terap: string;
  dt_insert: string;
}

interface Responsavel {
  nome: string;
  filiacao: string;
  telefone: string;
}

export class Anamnese {
  private idPaciente: string;
  private pacActions: any;
  private pacienteData: Paciente | null;
  private idUser: string;
  private nome: string | undefined;
  private ocupacao: string;

  constructor(
    idPaciente: string,
    pacActions: any,
    idUser: string,
    nome: string | undefined,
    ocupacao: string
  ) {
    this.idPaciente = idPaciente;
    this.pacActions = pacActions;
    this.pacienteData = null;
    this.idUser = idUser;
    this.nome = nome;
    this.ocupacao = ocupacao;
  }

  public async PreparaDocumento(): Promise<any> {
    await this.buscarDadosPaciente();
    const docDefinition = this.CriaCorpoDocumento();
    const documento = this.GerarDocumento(docDefinition);
    return documento;
  }

  private async buscarDadosPaciente(): Promise<void> {
    try {
      const pacData: Paciente[] = await this.pacActions.fill(this.idPaciente);
      if (pacData && pacData.length > 0) {
        this.pacienteData = pacData[0]; // Atribui o primeiro objeto de paciente à variável pacienteData
        console.log(this.pacienteData);
      } else {
        console.error("Nenhum dado de paciente encontrado.");
      }
    } catch (error) {
      console.error("Erro ao buscar dados do paciente:", error);
    }
  }

  private CriaCorpoDocumento(): TDocumentDefinitions {
    if (!this.pacienteData) {
      console.error("Dados do paciente não encontrados.");
      return { content: [] };
    }

    const responsaveis =
      this.pacienteData.responsaveis &&
      this.pacienteData.responsaveis.length > 0
        ? this.pacienteData.responsaveis
            .map(
              (resp, index) =>
                `${index + 1}. Nome: ${resp.nome} / Filiação: ${
                  resp.filiacao
                } / Contato: ${resp.telefone}`
            )
            .join("\n")
        : "Nenhum responsável registrado.";

    const historico = this.pacienteData.historico || [];
    console.log(this.pacienteData.historico);
    let historico_medico = "";
    let alergias = "";
    let medicamentos_em_uso = "";
    let motivo = "";

    historico.forEach((item, index) => {
      if (
        item.historico_medico !== null &&
        item.historico_medico !== undefined
      ) {
        historico_medico += `${item.historico_medico}  | ${new Date(
          item.dt_insert
        ).toLocaleString()} | ${
          item.id_terap === "2115711416861374"
            ? "Administrador Clínica Praticar"
            : item.nome_terap
        }\n`;
      }
      if (item.alergias !== null && item.alergias !== undefined) {
        alergias += `${item.alergias}  | ${new Date(
          item.dt_insert
        ).toLocaleString()} | ${
          item.id_terap === "2115711416861374"
            ? "Clínica Praticar"
            : item.nome_terap
        }\n`;
      }
      if (
        item.medicamentos_em_uso !== null &&
        item.medicamentos_em_uso !== undefined
      ) {
        medicamentos_em_uso += `${item.medicamentos_em_uso} | ${new Date(
          item.dt_insert
        ).toLocaleString()} | ${
          item.id_terap === "2115711416861374"
            ? "Clínica Praticar"
            : item.nome_terap
        }\n`;
      }
      if (item.motivo !== null && item.motivo !== undefined) {
        motivo += `${item.motivo} | ${new Date(
          item.dt_insert
        ).toLocaleString()} | ${
          item.id_terap === "2115711416861374"
            ? "Clínica Praticar"
            : item.nome_terap
        }\n`;
      }
    });

    const docDefinition: TDocumentDefinitions = {
      content: [
        // Coluna 1 - Logo e Informações
        {
          columns: [
            {
              image: logo,
              width: 100,
              margin: [0, 0, 0, 10], // Margin: top, right, bottom, left
            },
            {
              stack: [
                {
                  text: [
                    {
                      text: "Nº do Prontuário / Paciente: ",
                      bold: true,
                      italics: true,
                    },
                    { text: this.pacienteData?.id_seq || "", fontSize: 10 },
                  ],
                  margin: [0, 0, 0, 5],
                },
                {
                  text: [
                    { text: "Data de entrada: ", bold: true, italics: true },
                    {
                      text: new Date(
                        this.pacienteData?.dt_insert
                      ).toLocaleDateString("pt-BR", { timeZone: "UTC" }),
                      fontSize: 10,
                    },
                  ],
                  margin: [0, 0, 0, 5],
                },
                {
                  text: [
                    { text: "Sexo: ", bold: true, italics: true },
                    {
                      text:
                        this.pacienteData?.genero === "M"
                          ? "Masculino"
                          : "Feminino",
                      fontSize: 10,
                    },
                  ],
                  margin: [0, 0, 0, 5],
                },
                {
                  text: [
                    { text: "Nascimento: ", bold: true, italics: true },
                    {
                      text: this.pacienteData?.dt_nasc
                        ? new Date(
                            this.pacienteData.dt_nasc
                          ).toLocaleDateString("pt-BR", { timeZone: "UTC" })
                        : "",
                      fontSize: 10,
                    },
                  ],
                  margin: [0, 0, 0, 5],
                },
                {
                  text: [
                    { text: "Idade: ", bold: true, italics: true },
                    {
                      text:
                        this.calculateAge(this.pacienteData?.dt_nasc).years +
                        " anos " +
                        this.calculateAge(this.pacienteData?.dt_nasc).months +
                        " meses " +
                        this.calculateAge(this.pacienteData?.dt_nasc).days +
                        " dias",
                      fontSize: 10,
                    },
                  ],
                  margin: [0, 0, 0, 5],
                },
                {
                  text: [
                    { text: "Impresso por: ", bold: true, italics: true },
                    {
                      text:
                        (this.nome ? ":" : "") +
                        (this.nome ?? "") +
                        (this.ocupacao ? ":" : "") +
                        (this.ocupacao ?? ""),
                      fontSize: 10,
                    },
                  ],
                  margin: [0, 0, 0, 5],
                },
                {
                  text: [
                    {
                      text: "Data e Hora da impressão: ",
                      bold: true,
                      italics: true,
                    },
                    { text: this.getCurrentDateTime(), fontSize: 10 },
                  ],
                  margin: [0, 0, 0, 5],
                },
              ],
              alignment: "right",
            },
          ],
        },
        { text: "Nome: ", bold: true, italics: true, margin: [10, 5, 0, 5] },
        {
          text: this.pacienteData.nome || "",
          fontSize: 10,
          margin: [10, 5, 0, 5],
        },
        {
          text: "Convênio: ",
          bold: true,
          italics: true,
          margin: [10, 5, 0, 5],
        },
        {
          text: this.pacienteData.convenio || "",
          fontSize: 10,
          margin: [10, 5, 0, 5],
        },
        {
          text: "Nº Cartão do Convênio: ",
          bold: true,
          italics: true,
          margin: [10, 5, 0, 5],
        },
        {
          text: this.pacienteData.n_cartao || "",
          fontSize: 10,
          margin: [10, 5, 0, 5],
        },
        {
          text: "Endereço: ",
          bold: true,
          italics: true,
          margin: [10, 5, 0, 5],
        },
        {
          text:
            (this.pacienteData.logradouro || "") +
            (this.pacienteData.numeral ? ", " : "") +
            (this.pacienteData.numeral || "") +
            (this.pacienteData.bairro ? ", " : "") +
            (this.pacienteData.bairro || "") +
            (this.pacienteData.cidade ? ", " : "") +
            (this.pacienteData.cidade || "") +
            (this.pacienteData.estado ? " - " : "") +
            (this.pacienteData.estado || "") +
            (this.pacienteData.cep ? ", CEP: " : "") +
            (this.pacienteData.cep || "") +
            (this.pacienteData.complemento ? ", " : "") +
            (this.pacienteData.complemento || ""),
          fontSize: 10,
          margin: [10, 5, 0, 5],
        },
        {
          text: "Responsáveis: ",
          bold: true,
          italics: true,
          margin: [10, 5, 0, 5],
        },
        { text: responsaveis, fontSize: 10, margin: [15, 5, 0, 5] }, // Adicionando margem esquerda para o campo "Responsáveis"
        {
          text: "CID Geral: ",
          bold: true,
          italics: true,
          margin: [10, 5, 0, 5],
        },
        {
          text:
            (this.pacienteData.cid_geral || "") +
            " - " +
            (this.pacienteData.diag_geral || ""),
          fontSize: 10,
          margin: [10, 5, 0, 5],
        },
        {
          text: "CID Fisio: ",
          bold: true,
          italics: true,
          margin: [10, 5, 0, 5],
        },
        {
          text:
            (this.pacienteData.cid_fisio || "") +
            " - " +
            (this.pacienteData.diag_fisio || ""),
          fontSize: 10,
          margin: [10, 5, 0, 5],
        },
        {
          text: "CID Fono: ",
          bold: true,
          italics: true,
          margin: [10, 5, 0, 5],
        },
        {
          text:
            (this.pacienteData.cid_fono || "") +
            " - " +
            (this.pacienteData.diag_fono || ""),
          fontSize: 10,
          margin: [10, 5, 0, 5],
        },
        {
          text: "CID Psico: ",
          bold: true,
          italics: true,
          margin: [10, 5, 0, 5],
        },
        {
          text:
            (this.pacienteData.cid_psico || "") +
            " - " +
            (this.pacienteData.diag_psico || ""),
          fontSize: 10,
          margin: [10, 5, 0, 5],
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 0,
              x2: 520,
              y2: 0,
              lineWidth: 1,
              lineColor: "#000000",
            },
          ],
          margin: [10, 25, 0, 5],
        },
        {
          text: historico_medico && "Histórico Clínico: ",
          bold: true,
          italics: true,
          alignment: "center",
          margin: [10, 25, 0, 5],
        },
        {
          text: historico_medico,
          fontSize: 10,
          alignment: "center",
          margin: [10, 5, 0, 5],
        },
        {
          text: alergias && "Alergias e Intolerâncias: ",
          bold: true,
          italics: true,
          alignment: "center",
          margin: [10, 5, 0, 5],
        },
        {
          text: alergias,
          fontSize: 10,
          alignment: "center",
          margin: [10, 5, 0, 5],
        },
        {
          text: medicamentos_em_uso && "Medicamentos em Uso: ",
          bold: true,
          italics: true,
          alignment: "center",
          margin: [10, 5, 0, 5],
        },
        {
          text: medicamentos_em_uso,
          fontSize: 10,
          alignment: "center",
          margin: [10, 5, 0, 5],
        },
        {
          text: "Médico Responsável: ",
          bold: true,
          italics: true,
          alignment: "center",
          margin: [10, 5, 0, 5],
        },
        {
          text:
            "Nome: " +
            (this.pacienteData.med_resp || "") +
            ", CRM: " +
            (this.pacienteData.crm || "") +
            ", Contato: " +
            (this.pacienteData.tel_med || ""),
          fontSize: 10,
          alignment: "center",
          margin: [10, 5, 0, 5],
        },
        {
          text: motivo && "Motivo da Consulta: ",
          bold: true,
          italics: true,
          alignment: "center",
          margin: [10, 5, 0, 5],
        },
        {
          text: motivo,
          fontSize: 10,
          alignment: "center",
          margin: [10, 5, 0, 5],
        },
      ],
      footer: function (currentPage: number, pageCount: number) {
        return [
          {
            text: `Página ${currentPage} de ${pageCount}`,
            alignment: "center",
            fontSize: 9,
          },
          {
            text: "© Clínica Praticar - Amor - Saúde - Vida",
            alignment: "center",
            fontSize: 8,
          },
        ];
      },
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
      },
    };

    return docDefinition;
  }

  private GerarDocumento(docDefinition: TDocumentDefinitions): any {
    pdfMake.createPdf(docDefinition).open();
  }

  private calculateAgeInDays(birthdate: string): number {
    const today = new Date();
    const birthDate = new Date(birthdate);
    const diffTime = Math.abs(today.getTime() - birthDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  private calculateAge(birthdate: string): {
    years: number;
    months: number;
    days: number;
  } {
    const ageInDays = this.calculateAgeInDays(birthdate);
    let years = Math.floor(ageInDays / 365);
    let months = Math.floor((ageInDays % 365) / 30);
    let days = (ageInDays % 365) % 30;

    return { years, months, days };
  }

  private getCurrentDateTime() {
    const currentDateTime = new Date();
    const day = currentDateTime.getDate();
    const month = currentDateTime.getMonth() + 1;
    const year = currentDateTime.getFullYear();
    const hour = currentDateTime.getHours().toString().padStart(2, "0");
    const minute = currentDateTime.getMinutes().toString().padStart(2, "0");
    const second = currentDateTime.getSeconds().toString().padStart(2, "0");
    return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
  }
}
