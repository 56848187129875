import { useContext, useState } from "react";
import { GeralContext } from "../../../../contexts/Geral/GeralContext";
import { Link } from "react-router-dom";
import MessageBox from "../../../../components/Utils/MessageBox";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { AuthContext } from "../../../../contexts/Auth/AuthContext";
export const AdicionaAtividade = () => {
  const { atividadeActions } = useContext(GeralContext);
  const [idAtiv, setIdAtiv] = useState<string>("");
  const [atividade, setAtividade] = useState<string>("");
  const [forma, setForma] = useState<string>("I");
  const [nPessoas, setNPessoas] = useState<number>(1);
  const [tmpAtividade, setTmpAtividade] = useState<string>("");
  const [valor, setValor] = useState<number>(0);
  const [message, setMessage] = useState<string>("");
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [showIcon, setShowIcon] = useState<boolean>(false);
  const auth = useContext(AuthContext);
  const idUser = auth.user?.id_user;
  const IsAdministrador = !!(
    auth.user?.ocupacao === "Administrador" || auth.user?.admin
  );
  const IsAdministradorSupervisor = !!(
    auth.user?.ocupacao === "Administrador" ||
    auth.user?.admin ||
    auth.user?.supervisor
  );
  const IsTerapeuta = !!(auth.user?.ocupacao === "Terapeuta");
  const IsMonitor = !!(auth.user?.ocupacao === "Monitor");
  const IsAdministrativo = !!(auth.user?.ocupacao === "Administrativo");

  const handleSubmit = async () => {
    if (atividade && forma && nPessoas && tmpAtividade && idUser) {
      const tmpformatted = tmpAtividade + ":00";
      const ItemAdded = await atividadeActions.add({
        id_ativ: idAtiv,
        atividade: atividade,
        forma: forma,
        n_pessoas: nPessoas,
        tmp_atividade: tmpformatted,
        id_user: idUser,
      });
      if (ItemAdded) {
        setMessage("Dados inseridos com sucesso");
        // Limpar os campos após salvar
        setIdAtiv("");
        setAtividade("");
        setForma("I");
        setNPessoas(1);
        setValor(0);
        setTmpAtividade("");
      } else {
        setMessage("Não foi possível inserir os dados.");
        setShowIcon(true);
      }
    } else {
      setMessage("Por favor, preencha todos os campos.");
      setShowIcon(true);
    }
    setShowMessage(true);
  };

  const handleCloseMessage = () => {
    setShowMessage(false);
  };

  return (
    <div className="min-h-screen flex items-center justify-center pt-12">
      {showMessage && (
        <MessageBox
          message={message}
          onClose={() => {
            setShowIcon(false);
            handleCloseMessage();
          }}
          icon={
            showIcon ? (
              <ExclamationCircleIcon className="w-10 h-10 mx-auto text-red-500" />
            ) : null
          }
        />
      )}{" "}
      <form className="w-full max-w-md">
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-3xl font-bold leading-8 text-gray-900 border-b-4 border-indigo-500 pb-2">
              Cadastrar Atividades
            </h2>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full">
                <label
                  htmlFor="atividade"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Atividade
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="atividade"
                    id="atividade"
                    autoComplete="given-name"
                    value={atividade}
                    className="block w-full rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setAtividade(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="forma"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Forma
                </label>
                <div className="mt-2">
                  <select
                    name="forma"
                    id="forma"
                    autoComplete="forma"
                    value={forma}
                    onChange={(e) => {
                      setForma(e.target.value);
                      if (e.target.value === "I") {
                        setNPessoas(1);
                      }
                    }}
                    className="block w-full rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    required
                  >
                    <option value="I">Individual</option>
                    <option value="G">Em grupo</option>
                  </select>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="npessoas"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Número de Pessoas
                </label>
                <div className="mt-2">
                  <input
                    type="number"
                    name="npessoas"
                    id="npessoas"
                    autoComplete="npessoas"
                    value={nPessoas}
                    required
                    onChange={(e) => setNPessoas(parseInt(e.target.value))}
                    className={`block w-full rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                      forma === "I" ? "cursor-not-allowed" : ""
                    }`}
                    disabled={forma === "I"}
                  />
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="tmpatidade"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Tempo de Atividade
                </label>
                <div className="mt-2">
                  <input
                    type="time"
                    name="tmpatidade"
                    id="tmpatidade"
                    autoComplete="tmpatv"
                    value={tmpAtividade}
                    onChange={(e) => setTmpAtividade(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6 pb-12">
          <Link
            to="/atividade"
            className="text-indigo-600 hover:text-indigo-900"
          >
            Voltar
          </Link>
          <button
            type="button"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleSubmit}
          >
            Salvar
          </button>
        </div>
      </form>
    </div>
  );
};
