import React, { useContext, useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/pt-br";
import {
  CursorArrowRaysIcon,
  ExclamationCircleIcon,
  CalendarDaysIcon,
  TableCellsIcon,
} from "@heroicons/react/24/outline";

import { Button } from "@material-tailwind/react";
  import html2canvas from "html2canvas";

import jsPDF from "jspdf";

import Modal from "react-modal";
import { GeralContext } from "../../../contexts/Geral/GeralContext";
import { PessoaContext } from "../../../contexts/Pessoa/PessoaContext";
import MessageBox from "../../../components/Utils/MessageBox";
import { AuthContext } from "../../../contexts/Auth/AuthContext";
import CustomCalendar from "../../../components/Utils/Calendar";
import CustomCalendarGrupo from "../../../components/Utils/CalendarGrupo";
import Legend from "../../../components/Utils/Legend";

const localizer = momentLocalizer(moment);

const html2pdf = require("html2pdf.js");
const domToPdf = require('dom-to-pdf');
const domtoimage= require('dom-to-image');


const customStyles: Modal.Styles = {
  content: {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -20%)",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    maxWidth: "800", // Ajuste a largura máxima conforme necessário
    width: "50%", // Usando uma porcentagem para a largura
    maxHeight: "90vh", // Ajuste a altura máxima conforme necessário
    overflowY: "auto",
  },
  overlay: {
    zIndex: 999, // Certifique-se de que o modal fique acima de outros elementos
  },
};

interface ConvenioSelecionado {
  codconvenio: string;
  ncartao: string | null;
}

interface ConvenioItem {
  codconvenio: string;
  convenio: string;
}
interface AgendaItem {
  id_agenda: string | null;
  nome_paciente: string;
  id_pac: string;
  codconvenio: string;
  dt_evento: string;
  hr_inicio: string;
  hr_fim: string;
  nome_terapeuta: string;
  id_terap: string;
  paciente: string;
  convenio: string;
  n_sala: string;
  codproc: string;
  atividade: string;
  n_pessoas: string;
  horario: string;
  tmp_atividade: string;
  situacao: string; // Nova propriedade para a situação do evento
  observacao: string;
}

interface Atividade {
  id_terap: string;
  terapeuta: string;
  id_administrativo: string;
  administrativo: string;
  id_ativ: string;
  atividade: string;
  tmp_atividade: string;
}

interface Turnos {
  manha: Atividade;
  tarde: Atividade;
}

interface SalaItem {
  id_sala: number;
  n_sala: string;
  turnos: {
    [key: string]: Turnos;
  };
}

interface PacienteItem {
  id_pac: string;
  nome: string;
  dt_nasc: string;
  genero: string;
  responsavel: string;
  codconvenio: string;
  convenio: string;
  periodo: string;
  dias: number;
  sessoes: number;
  cep: string;
  estado: string;
  cidade: string;
  bairro: string;
  logradouro: string;
  numeral: string;
  complemento: string;
  telefone: string;
  convenios_selecionados: ConvenioSelecionado[]; // Novo campo
}

interface TerapeutaItem {
  id_terap: string;
  id_espec: string;
  especialidade: string;
  nome: string;
  dt_inicio: string;
  dt_fim: string;
  email: string;
  telefone: number;
}

export function AgendaManha() {
  const { agendaActions, salaActions, agendaRecepcaoActions } =
    useContext(GeralContext);

  const { terapActions, pacActions } = useContext(PessoaContext);
  const [showAddModal, setShowAddModal] = useState(false);
  const [tableAgenda, setTableAgenda] = useState<AgendaItem[]>([]);
  const [tableSala, setTableSala] = useState<SalaItem[]>([]);
  const [tablePac, setTablePac] = useState<PacienteItem[]>([]);
  const [tableTerap, setTableTerap] = useState<TerapeutaItem[]>([]);
  const [eventStartTime, setEventStartTime] = useState<string>("");
  const [eventEndTime, setEventEndTime] = useState<string>("");
  const [selectedPaciente, setSelectedPaciente] = useState("");
  const [selectedSala, setSelectedSala] = useState<string | null>(null);
  const [codProc, setCodproc] = useState<string>("");
  const [atividade, setAtividade] = useState<string>("");
  const [selectedConvenio, setSelectedConvenio] = useState("");
  const { convenioActions } = useContext(GeralContext);
  const [observacao, setObservacao] = useState<string>("");
  const [conveniosDisponiveis, setConveniosDisponiveis] = React.useState<
    ConvenioItem[]
  >([]);
  const [conveniosPaciente, setConveniosPaciente] = React.useState<
    ConvenioItem[]
  >([]);
  const [eventSituation, setEventSituation] = useState("");

  const [tmpAtividade, setTmpAtividade] = useState<string>("");
  const [selectedTerapeuta, setSelectedTerapeuta] = useState("");
  const [message, setMessage] = useState<string>("");
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [showIcon, setShowIcon] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState(() => {
    const storedDate = localStorage.getItem("selectedDateManha");
    return storedDate ? new Date(storedDate) : new Date();
  });

  const auth = useContext(AuthContext);
  const idUser = auth.user?.id_user;
  const maxTime = new Date(0, 0, 0, 12);

  // Verificar se é manhã ou tarde com base no minTime e maxTime
  const period = maxTime.getHours() <= 12 ? "manha" : "tarde";

  let dayOfWeek = moment(selectedDate).locale("pt").format("dddd");

  // Capitaliza a primeira letra
  dayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);

  // Função para buscar os convênios disponíveis
  useEffect(() => {
    const fetchConvenioData = async () => {
      try {
        const convenioData = await convenioActions.fill();
        setConveniosDisponiveis(convenioData);
      } catch (error) {
        console.error("Erro ao buscar dados dos convenios:", error);
      }
    };
    fetchConvenioData();
  }, [convenioActions]);

  const fetchItemData = async (
    agendaActions: any,
    setTableAgenda: React.Dispatch<React.SetStateAction<AgendaItem[]>>
  ) => {
    let ItemData: AgendaItem[];
    try {
    console.log(selectedDate.toISOString());
  
      ItemData = await agendaActions.filld(selectedDate.toISOString());
      console.log(selectedDate.toISOString());
  
      setTableAgenda(ItemData);
      console.log(tableAgenda);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };

  const handleAddEvent = async () => {
    // Verifique se o horário de início está entre 8:00 e 12:00
    const startTime = moment(eventStartTime, "HH:mm");
    const eightAM = moment("08:00", "HH:mm");
    const twelvePM = moment("12:00", "HH:mm");

    if (startTime.isBefore(eightAM) || startTime.isAfter(twelvePM)) {
      // Se o horário de início estiver fora do intervalo permitido, exiba uma mensagem de erro
      setMessage("O horário de início deve estar entre 8:00 e 12:00.");
      setShowMessage(true);
      setShowIcon(true);
      return; // Saia da função sem adicionar o evento
    }

    if (
      (idUser &&
        selectedPaciente &&
        selectedDate &&
        eventStartTime &&
        selectedSala !== null) ||
      (idUser &&
        selectedPaciente &&
        selectedDate &&
        eventStartTime &&
        selectedSala)
    ) {
      try {
        // Calcule o horário de término
        const startDateTime = moment(eventStartTime, "HH:mm");
        const duration = moment.duration(tmpAtividade);
        let endTimeFormatted: string;

        if (eventEndTime) {
          endTimeFormatted = moment(eventEndTime, "HH:mm").format("HH:mm");
        } else {
          const endDateTime = startDateTime.clone().add(duration);
          endTimeFormatted = endDateTime.format("HH:mm");
        }

        // Formate a data para 'YYYY-MM-DD'
        const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
        let ItemAdded;

        if (eventEndTime) {
          ItemAdded = await agendaActions.add({
            id_agenda: null,
            id_pac: selectedPaciente,
            n_sala: selectedSala,
            codconvenio: selectedConvenio,
            codproc: null,
            dt_evento: formattedDate,
            hr_inicio: eventStartTime,
            hr_fim: endTimeFormatted,
            id_terap: selectedTerapeuta,
            situacao: eventSituation,
            id_user: idUser,
            observacao: observacao,
          });
        } else {
          ItemAdded = await agendaActions.add({
            id_agenda: null,
            id_pac: selectedPaciente,
            n_sala: selectedSala,
            codconvenio: selectedConvenio,
            codproc: codProc,
            dt_evento: formattedDate,
            hr_inicio: eventStartTime,
            hr_fim: endTimeFormatted,
            id_terap: selectedTerapeuta,
            situacao: eventSituation,
            id_user: idUser,
            observacao: observacao,
          });
        }

        if (ItemAdded) {
          // Se for bem-sucedido
          setMessage("Dados atualizados com sucesso.");
          fetchItemData(agendaActions, setTableAgenda);
          setShowMessage(true);
          setShowIcon(false);
        } else {
          // Se a API não forneceu mais detalhes
          setMessage("Erro ao processar a solicitação.");
          setShowMessage(true);
          setShowIcon(true);
        }
      } catch (error: any) {
        // Tratamento de exceções inesperadas (AxiosError)
        if (error.response) {
          // Se a resposta do erro contém dados
          const errorMessage =
            error.response.data?.error || "Erro ao processar a solicitação.";
          setMessage(errorMessage);
        } else {
          // Se não há resposta, um erro inesperado ocorreu
          setMessage(
            "Ocorreu um erro inesperado ao tentar atualizar os dados."
          );
        }
        setShowMessage(true);
        setShowIcon(true);
      }
    } else {
      // Mensagem de erro se campos estiverem faltando
      setMessage("Por favor, preencha todos os campos.");
      setShowIcon(true); // Exibe o ícone de erro
    }
  };

  useEffect(() => {
    fetchItemData(agendaActions, setTableAgenda);
  }, [agendaActions, setTableAgenda,selectedDate]);

  useEffect(() => {
    const fetchItemData = async () => {
      let ItemData: AgendaItem[];
      try {
            

      ItemData = await agendaActions.filld(selectedDate.toISOString());
         console.log(selectedDate.toISOString());
  
        setTableAgenda(ItemData);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };
    fetchItemData();
  }, [agendaActions,selectedDate]);

  useEffect(() => {
    const fetchItemData = async () => {
      let ItemData: SalaItem[];
           let ItemData2: AgendaItem[];

      try {
        ItemData = await salaActions.fill(period, dayOfWeek);
        setTableSala(ItemData);
    
      ItemData2 = await agendaActions.filld(selectedDate.toISOString());
     console.log(selectedDate.toISOString());
  
        setTableAgenda(ItemData2);  
        console.log(tableAgenda);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };
    fetchItemData();
  }, [salaActions, period, dayOfWeek,selectedDate]);

  useEffect(() => {
    // Encontre o codproc correspondente à sala selecionada
    if (selectedSala !== null) {
      const selectedRoom = tableSala.find(
        (item) => item.n_sala.toString() === selectedSala.toString()
      );
      console.log(tableSala);
      if (selectedRoom) {
        // Atribua os valores de codproc e tmp_atividade com base na sala selecionada
        //        setCodproc(selectedRoom.codproc);
        //       setAtividade(selectedRoom.atividade);
        //      setTmpAtividade(selectedRoom.tmp_atividade);
      } else {
        // Lidere com o caso em que não há correspondência
        //    setCodproc('');
        //    setAtividade('');
        //   setTmpAtividade('');
      }
    }
  }, [selectedSala, tableSala,selectedDate]);

  useEffect(() => {
    const fetchItemData = async () => {
      let ItemData: PacienteItem[];
      try {
        console.log(codProc);
        ItemData = await pacActions.fill(
          undefined,
          codProc,
          true,
          selectedSala
        );
        setTablePac(ItemData);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };
    fetchItemData();
  }, [pacActions, codProc, selectedSala,selectedDate]);

  useEffect(() => {
    const fetchItemData = async () => {
      let ItemData: TerapeutaItem[];
      try {
        ItemData = await terapActions.fill();
        setTableTerap(ItemData);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };
    fetchItemData();
  }, [terapActions,selectedDate]);

  const handleAddButtonClick = () => {
    setShowAddModal(true);
  };

  const handleCloseMessage = () => {
    setShowAddModal(false); // Feche o modal após adicionar o evento com sucesso
    setShowMessage(false);
  };

  const handleCloseModal = () => {
    // Limpar todos os campos do formulário
    setSelectedPaciente("");
    setSelectedSala(null);
    setSelectedTerapeuta("");
    setEventStartTime("");
    setEventEndTime("");
    setCodproc("");
    setAtividade("");
    setTmpAtividade("");

    // Fechar o modal
    setShowAddModal(false);
  };

  // Atualiza a data selecionada no local storage sempre que ela mudar
  useEffect(() => {
    localStorage.setItem("selectedDateManha", selectedDate.toISOString());
    localStorage.setItem("dataatual", selectedDate.toISOString());

  }, [selectedDate]);

  function handleSalaChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const selectedSala = e.target.value;
    setSelectedSala(selectedSala);

    if (selectedSala && selectedDate) {

// Passar o objeto Date para getDayOfWeek
const dayOfWeek = getDayOfWeek(selectedDate); // Obter o dia da semana baseado na data selecionada

console.log(dayOfWeek);

      
      const sala = tableSala.find((sala) => sala.n_sala === selectedSala);

      if (sala && sala.turnos[dayOfWeek]) {
        const turno = sala.turnos[dayOfWeek];

        // Preenche os campos com os dados obtidos do turno
        if (turno) {
          setSelectedTerapeuta(turno.manha.id_terap || "");
          setAtividade(turno.manha.atividade || "");
          setTmpAtividade(turno.manha.tmp_atividade || "");
        }
      } else {
        // Limpa os campos se não houver dados para o dia da semana
        setSelectedTerapeuta("");
        setAtividade("");
        setTmpAtividade("");
      }
    }
  }

  // Função para obter o dia da semana a partir da data selecionada
  function getDayOfWeek(date: Date): string {
    const daysOfWeek = [
      "Domingo",
      "Segunda-Feira",
      "Terça-Feira",
      "Quarta-Feira",
      "Quinta-Feira",
      "Sexta-Feira",
      "Sábado",
    ];
    return daysOfWeek[date.getDay()];
  }

  // Função para retroceder a data
  const handlePreviousDate = () => {
    setSelectedDate((prevDate) => {
      const newDate = moment(prevDate).subtract(1, "day").toDate();
      return newDate;
    });
  };

  // Função para avançar a data
  const handleNextDate = () => {
    setSelectedDate((prevDate) => {
      const newDate = moment(prevDate).add(1, "day").toDate();
      return newDate;
    });
  };

  const handlePacienteChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const pacienteId = e.target.value;
    setSelectedPaciente(pacienteId); // Mantém o id como string

    if (pacienteId) {
      // Encontrar o paciente selecionado
      const paciente = tablePac.find((p) => p.id_pac.toString() === pacienteId);

      if (paciente) {
        // Filtrar os convênios disponíveis baseados nos convênios selecionados do paciente
        const conveniosSelecionados: ConvenioSelecionado[] =
          paciente.convenios_selecionados || [];

        const conveniosFiltrados = conveniosDisponiveis.filter((convenio) =>
          conveniosSelecionados.some(
            (c: ConvenioSelecionado) => c.codconvenio === convenio.codconvenio
          )
        );

        setConveniosPaciente(conveniosFiltrados);

        // Verificar se há apenas um convênio e selecioná-lo automaticamente
        if (conveniosFiltrados.length === 1) {
          setSelectedConvenio(conveniosFiltrados[0].codconvenio);
        }
      } else {
        setConveniosPaciente([]);
        setSelectedConvenio(""); // Reseta o convênio selecionado se não houver paciente
      }
    }
  };

  const handleReplicarButtonClick = () => {
    const nextWeekDate = moment(selectedDate)
      .add(1, "week")
      .format("dddd, D [de] MMMM [de] YYYY");
    const confirmReplicate = window.confirm(
      `A agenda será replicada para a próxima semana, ${nextWeekDate}. Deseja confirmar?`
    );

    if (confirmReplicate) {
      replicar();
    }
  };
  const handleRecepButtonClick = () => {
    // Calculate the start and end of the week that includes `selectedDate` (Sunday to Saturday)
    const startOfWeek = moment(selectedDate).startOf("week"); // Sunday
    const endOfWeek = moment(selectedDate).endOf("week"); // Saturday

    // Format the start and end dates for display
    const startDateFormatted = startOfWeek.format(
      "dddd, D [de] MMMM [de] YYYY"
    );
    const endDateFormatted = endOfWeek.format("dddd, D [de] MMMM [de] YYYY");

    // Create a confirmation message with the week range
    const confirmEnviar = window.confirm(
      `A agenda será enviada para a recepção com a semana de ${startDateFormatted} a ${endDateFormatted}. Deseja confirmar?`
    );

    if (confirmEnviar) {
      enviar();
    }
  };
const handleRecepButtonClickT = () => {
  const confirmEnviar = window.confirm(
    `A agenda será enviada para a recepção com a data ${moment(selectedDate).format('DD-MM-YYYY')} turno MANHÃ. Deseja confirmar?`
  );

  if (confirmEnviar) {
    enviarT();
  }
};


  



  
  const replicar = async () => {
    try {
      // Data atual (data selecionada)
      const dataAtual = moment(selectedDate).format("YYYY-MM-DD");

      // Data da próxima semana
      const nextWeek = moment(selectedDate).add(7, "days").format("YYYY-MM-DD");

      // Fazendo a requisição para replicar
      const response = await agendaActions.replicar(
        dataAtual,
        nextWeek,
        String(idUser)
      );

      console.log(
        "Agenda replicada com sucesso para a data:",
        nextWeek,
        response
      );
    } catch (error) {
      console.error("Erro ao replicar a agenda:", error);
    }
  };

  const enviar = async () => {
    try {
      // Data atual (data selecionada)
      const dataAtual = moment(selectedDate).format("YYYY-MM-DD");

      // Fazendo a requisição para enviar
      const response = await agendaRecepcaoActions.replicar(
        dataAtual,
        "",
        String(idUser)
      );

      console.log(
        "Agenda enviada com sucesso para a semana escolhida:",
        response
      );
    } catch (error) {
      console.error("Erro ao enviar a agenda:", error);
      alert("Erro ao enviar a agenda. Tente novamente mais tarde.");
    }
  };

   const enviarT = async () => {
    try {
      // Data atual (data selecionada)
      const dataAtual = moment(selectedDate).format("YYYY-MM-DD");

      // Fazendo a requisição para enviar
      const response = await agendaRecepcaoActions.replicar(
        dataAtual,
        'M',
        String(idUser)
      );

      console.log(
        "Agenda enviada com sucesso para a DATA e TURNO escolhidos:",
        response
      );
    } catch (error) {
      console.error("Erro ao enviar a agenda:", error);
      alert("Erro ao enviar a agenda. Tente novamente mais tarde.");
    }
  };


  
  const exportCalendarGrupoToPDF = async () => {
  const element = document.getElementById("calendariogrupo");
   if (!element) return alert("Div não encontrada.");

  // Recupera a data para o título
  const titulo = `Clínica Praticar - Agenda Manhã - ${selectedDate.toLocaleDateString("pt-BR")}`;

  // Inicializa o jsPDF com a orientação retrato
  const doc = new jsPDF("landscape", "mm", "a4");
  const pdfWidth = 297; // Largura total da página A4 em retrato
  const pdfHeight = 210; // Altura total da página A4 em retrato

  // Captura o HTML como imagem com dom-to-image
  domtoimage.toPng(element).then((dataUrl: string) => {
    const img = new Image();
    img.src = dataUrl;

    img.onload = () => {
      // Dimensões originais do elemento
      const originalWidth = img.width;
      const originalHeight = img.height;

      // Calcula a proporção da imagem
      const imgRatio = originalWidth / originalHeight;
      const pageRatio = pdfWidth / pdfHeight;

      // Ajusta a imagem proporcionalmente para caber na página A4
      let imgWidth, imgHeight;
      
      if (imgRatio > pageRatio) {
        // Se a imagem for mais larga que a página, ajusta a largura
        imgWidth = pdfWidth;
        imgHeight = pdfWidth / imgRatio;
      } else {
        // Se a imagem for mais alta que a página, ajusta a altura
        imgHeight = pdfHeight;
        imgWidth = pdfHeight * imgRatio;
      }

      // Adiciona a imagem ajustada à página
      doc.addImage(
        img,
        "PNG",
        0, // Posição X na página
        10, // Posição Y na página
        imgWidth, // Largura ajustada
        imgHeight*1.2 // Altura ajustada
      );

      // Adiciona o título no rodapé
      doc.setFontSize(16);
      doc.text(titulo, pdfWidth / 2, 5, { align: "center" });

      // Salva o PDF
      doc.save("calendario-grupo.pdf");
    };
  }).catch((error: any) => {
    console.error("Erro ao gerar a imagem:", error);
  });
}



  
const exportCalendarToPDF = async () => {
 const element = document.getElementById("calendariogeral");
  if (!element) return alert("Div não encontrada.");

  // Recupera a data para o título

  const titulo = `Clínica Praticar - Agenda Manhã - ${selectedDate.toLocaleDateString("pt-BR")}`;

  // Inicializa o jsPDF com a orientação paisagem
  const doc = new jsPDF("landscape", "mm", "a4");
  const marginTop = 10; // Margem superior de 0.5 cm
  const marginReduction = 15; // Redução de 10% nas dimensões da imagem
  const pdfWidth = 297; // Largura total da página A4 em paisagem
  const pdfHeight = 210; // Altura total da página A4 em paisagem

  // Captura o HTML como imagem com dom-to-image
  domtoimage.toPng(element).then((dataUrl: string) => {
    const img = new Image();
    img.src = dataUrl;

    img.onload = () => {
      // Dimensões originais do elemento
      const originalWidth = img.width;
      const originalHeight = img.height;

      // Ajusta as dimensões da imagem para redução de 10%
      const reducedWidth = originalWidth * 0.9; // Reduz 10% da largura
      const reducedHeight = originalHeight * 0.9; // Reduz 10% da altura

      // **Dividir a imagem ao meio horizontalmente**
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        alert("Erro ao criar canvas para manipulação da imagem.");
        return;
      }

      // Configura o canvas para metade da largura reduzida
      canvas.width = reducedWidth / 2;
      canvas.height = reducedHeight;

      // Parte 1: Metade esquerda
      ctx.drawImage(
        img,
        0, // sx (recorte inicial no eixo X)
        0, // sy (recorte inicial no eixo Y)
        originalWidth / 2, // sWidth (largura do recorte original)
        originalHeight, // sHeight (altura do recorte original)
        0, // dx (posição inicial no canvas X)
        0, // dy (posição inicial no canvas Y)
        canvas.width, // dWidth (largura no canvas)
        canvas.height // dHeight (altura no canvas)
      );
      const leftHalfDataUrl = canvas.toDataURL("image/png");

      // Parte 2: Metade direita
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Limpa o canvas
      ctx.drawImage(
        img,
        originalWidth / 2, // sx (recorte inicial no eixo X)
        0, // sy (recorte inicial no eixo Y)
        originalWidth / 2, // sWidth (largura do recorte original)
        originalHeight, // sHeight (altura do recorte original)
        0, // dx (posição inicial no canvas X)
        0, // dy (posição inicial no canvas Y)
        canvas.width, // dWidth (largura no canvas)
        canvas.height // dHeight (altura no canvas)
      );
      const rightHalfDataUrl = canvas.toDataURL("image/png");

      // Página 1: Metade esquerda
      doc.addImage(
        leftHalfDataUrl,
        "PNG",
        marginTop, // Margem superior mínima (0.5 cm)
        marginTop, // Margem superior mínima (0.5 cm)
        pdfWidth - marginReduction, // Largura reduzida (10% de margem total)
        pdfHeight - marginReduction // Altura reduzida (10% de margem total)
      );

      // Adiciona o título no rodapé da página 1
      doc.setFontSize(16);
     doc.text(titulo, pdfWidth / 2 , marginTop - 5, { align: "center" });

      // Página 2: Metade direita
      doc.addPage();
      doc.addImage(
        rightHalfDataUrl,
        "PNG",
        marginTop, // Margem superior mínima (0.5 cm)
        marginTop, // Margem superior mínima (0.5 cm)
        pdfWidth - marginReduction, // Largura reduzida (10% de margem total)
        pdfHeight - marginReduction // Altura reduzida (10% de margem total)
      );

      // Adiciona o título no rodapé da página 2
 doc.text(titulo, pdfWidth / 2 , marginTop - 5, { align: "center" });

      // Salva o PDF
      doc.save("calendario-manha.pdf");
    };
  }).catch((error: any) => {
    console.error("Erro ao gerar a imagem:", error);
  });
}
  
  
  
    useEffect(() => {
    // Log do estado após a atualização
    console.log("Tabela da agenda atualizada:", tableAgenda);
  }, [tableAgenda]); // Esse efeito será disparado sempre que tableAgenda mudar

  
  return (

    <div className="w-full m-0 p-0" style={{ margin: 0, padding: 0 }}>
      <div className="flex justify-between items-start mb-4">
        {/* Botão Agendar à esquerda */}
        <Button
          placeholder=""
          variant="outlined"
          size="lg"
          onClick={handleAddButtonClick}
          className="flex items-center justify-center text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
        >
          <CursorArrowRaysIcon className="w-6 h-6 mr-2" />
          Agendar
        </Button>
        <Button
          placeholder=""
          variant="outlined"
          size="lg"
          onClick={handleRecepButtonClick}
          className="flex items-center justify-center text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
        >
          
          <CalendarDaysIcon className="w-6 h-6 mr-2" />
          Enviar Semana
        </Button>
     <Button
          placeholder=""
          variant="outlined"
          size="lg"
          onClick={handleRecepButtonClickT}
          className="flex items-center justify-center text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
        >
       

          <TableCellsIcon className="w-6 h-6 mr-2" />
          Enviar Turno
        </Button>
     
        {/* Botão Replicar à direita com fundo amarelo */}
        <Button
          placeholder=""
          variant="outlined"
          size="lg"
          onClick={handleReplicarButtonClick}
          className="text-black bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >
          Replicar
        </Button>
      </div>

        <div id="tudao" className="w-full m-0 p-0" style={{ margin: 0, padding: 0 }}>
      {/* Condição para renderizar o calendário apenas quando tableAgenda estiver carregado */}
        <div className="w-full m-0 p-0" style={{ margin: 0, padding: 0 }}>
          <CustomCalendar
            tableAgenda={tableAgenda}
            tablePac={tablePac} // Exemplo de outras props
            tableSala={tableSala}
            tableTerap={tableTerap}
            localizer={localizer}
            minTime={new Date(0, 0, 0, 8)}
            maxTime={new Date(maxTime)}
            selectedDate={selectedDate}
          />
          <button onClick={exportCalendarToPDF}>Exportar para PDF</button>
        </div>
   
      
        {/* Texto descritivo sobre o calendário do grupo */}
        <div className="w-full m-0 p-0" style={{ margin: 0, padding: 0 }}>
          <p
            style={{ fontSize: "1.2em", fontWeight: "bold", margin: "10px 0" }}
          >
            Atividades em Grupo
          </p>
        </div>

        {/* Calendário do grupo ao lado direito */}
        <div className="w-full m-0 p-0" style={{ margin: 0, padding: 0 }}>
          <CustomCalendarGrupo
            tableAgenda={tableAgenda}
            tablePac={tablePac}
            tableSala={tableSala}
            tableTerap={tableTerap}
            localizer={localizer}
            minTime={new Date(0, 0, 0, 8)}
            maxTime={new Date(maxTime)}
            selectedDate={selectedDate}
          />
          <button onClick={exportCalendarGrupoToPDF}>Exportar para PDF</button>
 
        </div>
      </div>

      <Modal
        isOpen={showAddModal}
        onRequestClose={handleCloseModal}
        style={customStyles}
      >
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          {showMessage && (
            <MessageBox
              message={message}
              onClose={() => {
                setShowIcon(false);
                handleCloseMessage();
              }}
              icon={
                showIcon ? (
                  <ExclamationCircleIcon className="w-10 h-10 mx-auto text-red-500" />
                ) : null
              }
            />
          )}
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              Agendamento -{" "}
              {moment(selectedDate).format("dddd, D [de] MMMM [de] YYYY")}
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={handleCloseModal}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <form className="p-4 md:p-5">
            <div className="grid gap-4 mb-4 grid-cols-2">
              {/* Select da Sala */}
              <div>
                <label
                  htmlFor="sala"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Sala
                </label>
                <select
                  id="sala"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  value={selectedSala ?? ""}
                  onChange={handleSalaChange}
                  //onChange={(e) => setSelectedSala(e.target.value)}
                >
                  <option value="">Selecione a Sala</option>
                  {tableSala.map((sala) => (
                    <option key={sala.n_sala} value={sala.n_sala}>
                      {sala.n_sala}
                    </option>
                  ))}
                </select>
              </div>
              {/* Select do Paciente */}
              <div>
                <label
                  htmlFor="paciente"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Paciente
                </label>
                <select
                  id="paciente"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  value={selectedPaciente}
                  onChange={(e) => handlePacienteChange(e)}
                >
                  <option value="">Selecione o Paciente</option>
                  {tablePac
                    .sort((a, b) =>
                      a.nome.localeCompare(b.nome, "pt-BR", {
                        ignorePunctuation: true,
                      })
                    )
                    .map((paciente) => (
                      <option key={paciente.id_pac} value={paciente.id_pac}>
                        {paciente.nome}
                      </option>
                    ))}
                </select>
              </div>
              {/* Select do Plano de Saúde */}
              <div>
                <label
                  htmlFor="convenio"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Plano de Saúde
                </label>
                <select
                  id="convenio"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  value={selectedConvenio}
                  onChange={(e) => setSelectedConvenio(e.target.value)}
                >
                  <option value="">Selecione o Plano de Saúde</option>
                  {conveniosPaciente.map((convenio) => (
                    <option
                      key={convenio.codconvenio}
                      value={convenio.codconvenio}
                    >
                      {convenio.convenio}
                    </option>
                  ))}
                </select>
              </div>

              {/* Select do Terapeuta */}
              <div>
                <label
                  htmlFor="terapeuta"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Terapeuta
                </label>
                <select
                  id="terapeuta"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  value={selectedTerapeuta}
                  onChange={(e) => setSelectedTerapeuta(e.target.value)}
                  disabled
                >
                  <option value="">Selecione o Terapeuta</option>
                  {tableTerap.map((terapeuta) => (
                    <option key={terapeuta.id_terap} value={terapeuta.id_terap}>
                      {terapeuta.nome}
                    </option>
                  ))}
                </select>
              </div>
              {/* Campo de Hora Inicial */}
              <div>
                <label
                  htmlFor="start-time"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Hora Inicial
                </label>
                <input
                  type="time"
                  id="start-time"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  value={eventStartTime}
                  onChange={(e) => setEventStartTime(e.target.value)}
                />
              </div>
              {selectedSala === "0" ? (
                <div>
                  <label
                    htmlFor="start-end"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Hora Final
                  </label>
                  <input
                    type="time"
                    id="start-end"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    value={eventEndTime}
                    onChange={(e) => setEventEndTime(e.target.value)}
                  />
                </div>
              ) : (
                ""
              )}
              {selectedSala !== "0" ? (
                <div className="mt-2">
                  <span className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                    Tempo de Atividade: {tmpAtividade}
                  </span>
                  <span className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Atividade: {atividade}
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-span-2">
              <label
                htmlFor="observacao"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Observação
              </label>
              <input
                type="text"
                id="observacao"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                value={observacao}
                onChange={(e) => setObservacao(e.target.value)}
              />
            </div>
            <div className="col-span-2">
              <label
                htmlFor="situation"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Situação
              </label>
              <select
                id="situation"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                value={eventSituation}
                onChange={(e) => setEventSituation(e.target.value)}
              >
                {/* Opções para as situações */}
                <option value="Agendado">Agendado</option>
                <option value="Presente">Presente</option>
                <option value="Falta">Falta</option>
                <option value="Sai após este atendimento">
                  Sai após este atendimento
                </option>
                <option value="Avaliação">Avaliação</option>
                <option value="Devolutiva">Devolutiva</option>
                <option value="Troca de horário ou terapeuta">
                  Troca de horário ou terapeuta
                </option>
                <option value="Encaixe">Encaixe</option>
                <option value="Desmarcado pela clínica">
                  Desmarcado pela clínica
                </option>
                <option value="Controle Interno">Controle Interno</option>
              </select>
            </div>
            <div className="flex justify-end gap-4 mt-4">
              <button
                type="button"
                onClick={handleAddEvent}
                className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Concluir
              </button>
            </div>{" "}
          </form>
        </div>
      </Modal>
    </div>
  );
}
