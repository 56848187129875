import React from "react";
import { Button } from "@material-tailwind/react";
import * as XLSX from "xlsx"; // Importando a biblioteca xlsx
import logo from "../../images/logo/logo.png"; // Logo, caso precise inserir no arquivo

interface PatientData {
  dt_evento: string;
  hr_inicio: string;
  nome_paciente: string;
  nome_terapeuta: string;
  situacao: string;
  atividade: string;
  [key: string]: any;
}

interface RelAgendaPacienteProps {
  data: Array<PatientData>;
  fields: string[];
  loading: boolean;
}

const RelAgendaPacienteExcel: React.FC<RelAgendaPacienteProps> = ({
  data,
  fields,
  loading,
}) => {
  const groupedData = data.reduce(
    (acc, item) => {
      const patientName = item.nome_paciente || "Sem Nome";
      const date = item.dt_evento || "Sem Data";
      const time = item.hr_inicio || "Sem Horário";
      const therapist = item.nome_terapeuta || "Sem Terapeuta";

      if (!acc[patientName]) acc[patientName] = {};
      if (!acc[patientName][date]) {
        acc[patientName][date] = {
          times: [],
          therapists: [],
          situacoes: [],
          atividades: [],
        };
      }

      acc[patientName][date].times.push(time);
      acc[patientName][date].therapists.push(therapist);
      acc[patientName][date].situacoes.push(item.situacao || "N/A");
      acc[patientName][date].atividades.push(item.atividade || "N/A");

      return acc;
    },
    {} as {
      [patientName: string]: {
        [date: string]: {
          times: string[];
          therapists: string[];
          situacoes: string[];
          atividades: string[];
        };
      };
    }
  );

  // Função para gerar o Excel
  const generateExcel = () => {
    const dataToExport: any[] = [];

    // Adicionando título do relatório
    dataToExport.push(["Atendimentos Pacientes"]);

    // Cabeçalho da tabela
    dataToExport.push(["Paciente", "Data", "Horário", "Terapeuta", "Situação", "Atividade"]);

    // Iterando sobre os dados agrupados por paciente para adicionar na tabela
    Object.entries(groupedData).forEach(([patientName, dates]) => {
      Object.entries(dates).forEach(([date, { times, therapists, situacoes, atividades }]) => {
        times.forEach((time, i) => {
          dataToExport.push([
            patientName,
            date,
            time,
            therapists[i] || "",
            situacoes[i] || "N/A",
            atividades[i] || "N/A",
          ]);
        });
      });
    });

    // Criação do arquivo Excel
    const ws = XLSX.utils.aoa_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Relatório");

    // Exportando o arquivo Excel
    XLSX.writeFile(wb, "Relatório_Pacientes.xlsx");
  };

  return (
    <div className="mt-6 text-center">
      <button
        type="button"
        className="px-6 py-2 text-sm font-semibold text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-50"
        onClick={generateExcel}
        disabled={loading} // Desabilita o botão se loading for true
      >
        Gerar Excel
      </button>
    </div>
  );
};

export default RelAgendaPacienteExcel;
