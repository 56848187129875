import React from "react";
import { Button } from "@material-tailwind/react";
import * as pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import logo from "../../images/logo/logo.png";
import { TDocumentDefinitions } from "pdfmake/interfaces";

// Configurando as fontes no pdfMake
(pdfMake as any).vfs = pdfFonts.vfs;

interface PatientData {
  dt_evento: string;
  hr_inicio: string;
  nome_paciente: string;
  nome_terapeuta: string;
  situacao: string; // Nova coluna
  atividade: string; // Nova coluna
  [key: string]: any; // Para outros campos adicionais dinâmicos
}

interface RelAgendaPacienteProps {
  data: Array<PatientData>;
  fields: string[]; // Campos que serão exibidos no relatório
  loading: boolean;
}

const RelAgendaPaciente: React.FC<RelAgendaPacienteProps> = ({
  data,
  fields,
  loading
}) => {
  // Agrupando os dados por paciente
  const groupedData = data.reduce(
    (acc, item) => {
      const patientName = item.nome_paciente || "Sem Nome";
      const date = item.dt_evento || "Sem Data";
      const time = item.hr_inicio || "Sem Horário";
      const therapist = item.nome_terapeuta || "Sem Terapeuta";

      if (!acc[patientName]) acc[patientName] = {};

      if (!acc[patientName][date]) {
        acc[patientName][date] = {
          times: [],
          therapists: [],
          situacoes: [],
          atividades: [],
        };
      }

      acc[patientName][date].times.push(time);
      acc[patientName][date].therapists.push(therapist);
      acc[patientName][date].situacoes.push(item.situacao || "N/A"); // Adicionando a situação
      acc[patientName][date].atividades.push(item.atividade || "N/A"); // Adicionando o procedimento

      return acc;
    },
    {} as {
      [patientName: string]: {
        [date: string]: {
          times: string[];
          therapists: string[];
          situacoes: string[];
          atividades: string[];
        };
      };
    }
  );

  // Função para gerar o PDF
  const generatePDF = () => {
    const content: Array<any> = [
      {
        image: logo,
        width: 150,
        alignment: "center",
        margin: [0, 0, 0, 20],
      },
      {
        text: "Atendimentos Pacientes",
        style: "header",
      },
    ];

    // Iterando sobre os dados agrupados por paciente para gerar o PDF
    Object.entries(groupedData).forEach(([patientName, dates], index) => {
      const tableBody: Array<Array<any>> = [
        [
          {
            text: `Paciente: ${patientName}`,
            style: "tableHeader",
            colSpan: 5, // Atualizado para cobrir as novas colunas
            alignment: "center",
          },
          {},
          {},
          {},
          {},
        ],
        [
          { text: "Data", style: "tableHeader", alignment: "left" },
          { text: "Horário", style: "tableHeader", alignment: "left" },
          { text: "Terapeuta", style: "tableHeader", alignment: "left" },
          { text: "Situação", style: "tableHeader", alignment: "left" },
          { text: "Atividade", style: "tableHeader", alignment: "left" },
        ],
      ];

      Object.entries(dates).forEach(
        ([date, { times, therapists, situacoes, atividades }]) => {
          times.forEach((time, i) => {
            tableBody.push([
              { text: date, alignment: "left" },
              { text: time, alignment: "left" },
              { text: therapists[i] || "", alignment: "left" },
              { text: situacoes[i] || "N/A", alignment: "left" },
              { text: atividades[i] || "N/A", alignment: "left" },
            ]);
          });
        }
      );

      content.push({
        table: {
          headerRows: 2,
          widths: ["20%", "20%", "20%", "20%", "20%"],
          body: tableBody,
        },
        layout: {
          hLineWidth: () => 0.5,
          vLineWidth: () => 0.5,
          paddingLeft: () => 5,
          paddingTop: () => 2,
          paddingBottom: () => 2,
        },
        dontBreakRows: true,
        margin: index === 0 ? [0, 10, 0, 20] : [0, 10, 0, 20],
      });
    });

    const documentDefinition: TDocumentDefinitions = {
      content,
      styles: {
        header: {
          fontSize: 22,
          bold: true,
          margin: [0, 0, 0, 20],
          alignment: "left",
        },
        tableHeader: { fontSize: 10, bold: true, alignment: "left" },
      },
      defaultStyle: { fontSize: 9 },
      pageMargins: [40, 60, 40, 60],
    };

    // Exibe o PDF diretamente
    pdfMake.createPdf(documentDefinition).open();
  };

  return (
    <div className="mt-6 text-center">
      <button
        type="button"
        className="px-6 py-2 text-sm font-semibold text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-50"
        onClick={generatePDF}
        disabled={loading} // Desabilita o botão se loading for true
      >
        Gerar PDF
      </button>
    </div>
  );
};

export default RelAgendaPaciente;
