// MessageBox.tsx
import React from "react";

interface MessageBoxProps {
  message: string;
  onClose: () => void;
  icon?: React.ReactNode; // Nova propriedade opcional para o ícone
}

const MessageBox: React.FC<MessageBoxProps> = ({ message, icon, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg">
        {icon && <div className="mb-4">{icon}</div>}{" "}
        {/* Renderiza o ícone se ele estiver presente */}
        <div className="text-xl">{message}</div>
        <button
          onClick={onClose}
          className="mt-4 bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-500"
        >
          Fechar
        </button>
      </div>
    </div>
  );
};

export default MessageBox;
