import axios from "axios";
import { EspecParams } from "../types/Espec";
import { SalaParams } from "../types/Sala";
import { PacParams } from "../types/Paciente";
import { ConvenioParams } from "../types/Convenio";
import { ProcedimentoParams } from "../types/Procedimento";
import { TerapeutaParams } from "../types/Terapeuta";
import { MonitorParams } from "../types/Monitor";
import { AgendaParams } from "../types/Agenda";
import { HistoricoMedParams } from "../types/HistoricoMed";
import { AtividadeParams } from "../types/Atividade";
import { AdministrativoParams } from "../types/Administrativo";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

const makeApiRequest = async (endpoint: string, data: any) => {
  try {
    const response = await api.post(endpoint, data);
    return response.data;
  } catch (error) {
    // Handle errors here
    console.error("Error making API request:", error);
    throw error; // Re-throw the error to be caught by the caller
  }
};

export const useApi = () => ({
  validateToken: async (token: string) =>
    makeApiRequest("/validate", { token }),
  signin: async (email: string, password: string) =>
    makeApiRequest("/signin", { email, password }),
  updateuser: async (
    id_user: string,
    email: string,
    password: string,
    newpassword: string
  ) => makeApiRequest("/updateuser", { id_user, email, password, newpassword }),
  pacadd: async (PacParams: PacParams) => makeApiRequest("/pacadd", PacParams),
  pacedit: async (PacParams: PacParams) =>
    makeApiRequest("/pacedit", PacParams),
  pacdelete: async (id: string, id_user: string) =>
    makeApiRequest("/pacdelete", { id, id_user }),
  pacfill: async (
    id?: string,
    codProc?: string,
    flagAgenda?: boolean,
    salaRef?: number | null
  ) => makeApiRequest("/pacfill", { id, codProc, flagAgenda, salaRef }),
  salaadd: async (SalaParams: SalaParams) =>
    makeApiRequest("/salaadd", SalaParams),
  salaedit: async (SalaParams: SalaParams) =>
    makeApiRequest("/salaedit", SalaParams),
  saladelete: async (id: string, id_user: string) =>
    makeApiRequest("/saladelete", { id, id_user }),
  salafill: async (period?: string, dayOfWeek?: string) =>
    makeApiRequest("/salafill", { period, dayOfWeek }),
  salafilld: async (period?: string, dayOfWeek?: string, data?: string) =>
    makeApiRequest("/salafilld", { period, dayOfWeek, data }),

  especadd: async (EspecParams: EspecParams) =>
    makeApiRequest("/especadd", EspecParams),
  especedit: async (EspecParams: EspecParams) =>
    makeApiRequest("/especedit", EspecParams),
  especdelete: async (id: string, id_user: string) =>
    makeApiRequest("/especdelete", { id, id_user }),
  especfill: async () => makeApiRequest("/especfill", {}),
  convenioadd: async (ConvenioParams: ConvenioParams) =>
    makeApiRequest("/convenioadd", ConvenioParams),
  convenioedit: async (ConvenioParams: ConvenioParams) =>
    makeApiRequest("/convenioedit", ConvenioParams),
  conveniodelete: async (id: string, id_user: string) =>
    makeApiRequest("/conveniodelete", { id, id_user }),
  conveniofill: async () => makeApiRequest("/conveniofill", {}),
  procadd: async (ProcedimentoParams: ProcedimentoParams) =>
    makeApiRequest("/procadd", ProcedimentoParams),
  procedit: async (ProcedimentoParams: ProcedimentoParams) =>
    makeApiRequest("/procedit", ProcedimentoParams),
  procdelete: async (id: string, id_user: string) =>
    makeApiRequest("/procdelete", { id, id_user }),
  procfill: async () => makeApiRequest("/procfill", {}),
  ativadd: async (AtividadeParams: AtividadeParams) =>
    makeApiRequest("/ativadd", AtividadeParams),
  ativedit: async (AtividadeParams: AtividadeParams) =>
    makeApiRequest("/ativedit", AtividadeParams),
  ativdelete: async (id: string, id_user: string) =>
    makeApiRequest("/ativdelete", { id, id_user }),
  ativfill: async () => makeApiRequest("/ativfill", {}),
  terapadd: async (TerapeutaParams: TerapeutaParams) =>
    makeApiRequest("/terapadd", TerapeutaParams),
  terapfill: async () => makeApiRequest("/terapfill", {}),
  terapedit: async (TerapeutaParams: TerapeutaParams) =>
    makeApiRequest("/terapedit", TerapeutaParams),
  terapdelete: async (id_terap: string, id_user: string, id_hash: string) =>
    makeApiRequest("/terapdelete", { id_terap, id_user, id_hash }),
  monitoradd: async (MonitorParams: MonitorParams) =>
    makeApiRequest("/monitoradd", MonitorParams),
  monitorfill: async () => makeApiRequest("/monitorfill", {}),
  monitoredit: async (MonitorParams: MonitorParams) =>
    makeApiRequest("/monitoredit", MonitorParams),
  monitordelete: async (id_monitor: string, id_user: string, id_hash: string) =>
    makeApiRequest("/monitordelete", { id_hash, id_monitor, id_user }),
  agendaadd: async (AgendaParams: AgendaParams) =>
    makeApiRequest("/agendaadd", AgendaParams),
  agendafill: async () => makeApiRequest("/agendafill", {}),
  relatoriofill: async (dt_inicio: string, dt_fim: string, tp_agenda: string) =>
    makeApiRequest("/relatoriofill", { dt_inicio, dt_fim, tp_agenda }),
  agendafilld: async (dt_evento: string) =>
    makeApiRequest("/agendafilld", { dt_evento }),
  agendaedit: async (AgendaParams: AgendaParams) =>
    makeApiRequest("/agendaedit", AgendaParams),
  agendadelete: async (id: string, id_user: string) =>
    makeApiRequest("/agendadelete", { id, id_user }),
  agendareplicar: async (
    dataAtual: string,
    nextWeek: string,
    id_user: string
  ) => makeApiRequest("/agendareplicar", { dataAtual, nextWeek, id_user }),
  agendarecepadd: async (AgendaParams: AgendaParams) =>
    makeApiRequest("/agendarecepadd", AgendaParams),
  agendarecepfill: async () => makeApiRequest("/agendarecepfill", {}),
  agendarecepfilld: async (dt_evento: string) =>
    makeApiRequest("/agendarecepfilld", { dt_evento }),
  agendarecepedit: async (AgendaParams: AgendaParams) =>
    makeApiRequest("/agendarecepedit", AgendaParams),
  agendarecepdelete: async (id: string, id_user: string) =>
    makeApiRequest("/agendarecepdelete", { id, id_user }),
  agendarecepreplicar: async (
    dataAtual: string,
    nextWeek: string,
    id_user: string
  ) => makeApiRequest("/agendarecepreplicar", { dataAtual, nextWeek, id_user }),
  admadd: async (AdministrativoParams: AdministrativoParams) =>
    makeApiRequest("/admadd", AdministrativoParams),
  admedit: async (AdministrativoParams: AdministrativoParams) =>
    makeApiRequest("/admedit", AdministrativoParams),
  admfill: async () => makeApiRequest("/admfill", {}),
  admdelete: async (
    id_administrativo: string,
    id_user: string,
    id_hash: string
  ) => makeApiRequest("/admdelete", { id_administrativo, id_user, id_hash}),
  historicoadd: async (HistoricoMedParams: HistoricoMedParams) =>
    makeApiRequest("/historicoadd", HistoricoMedParams),
  cidfill: async () => makeApiRequest("/cidfill", {}),
  logout: async () => ({ status: true }), // Assuming logout doesn't require an API call
});
