import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { RequireAuth } from "./contexts/Auth/RequireAuth";
import { Perfil } from "./pages/Perfil";
import { Login } from "./pages/Login";
import { GerenciaPaciente } from "./pages/Pessoa/Paciente/Gerencia";
import { AdicionaPaciente } from "./pages/Pessoa/Paciente/Add";
import { EditaPaciente } from "./pages/Pessoa/Paciente/Edit";
import { GerenciaSala } from "./pages/Geral/Sala/Gerencia";
import { AdicionaSala } from "./pages/Geral/Sala/Add";
import { EditaSala } from "./pages/Geral/Sala/Edit";
import { GerenciaEspecialidade } from "./pages/Geral/Especialidade/Gerencia";
import { AdicionaEspecialidade } from "./pages/Geral/Especialidade/Add";
import { EditaEspecialidade } from "./pages/Geral/Especialidade/Edit";
import { GerenciaConvenio } from "./pages/Geral/Convenio/Gerencia";
import { AdicionaConvenio } from "./pages/Geral/Convenio/Add";
import { EditaConvenio } from "./pages/Geral/Convenio/Edit";
import { GerenciaProcedimento } from "./pages/Geral/Procedimento/Gerencia";
import { AdicionaProcedimento } from "./pages/Geral/Procedimento/Add";
import { EditaProcedimento } from "./pages/Geral/Procedimento/Edit";
import { GerenciaTerapeuta } from "./pages/Pessoa/Terapeuta/Gerencia";
import { AdicionaTerapeuta } from "./pages/Pessoa/Terapeuta/Add";
import { EditaTerapeuta } from "./pages/Pessoa/Terapeuta/Edit";
import { GerenciaMonitor } from "./pages/Pessoa/Monitor/Gerencia";
import { AdicionaMonitor } from "./pages/Pessoa/Monitor/Add";
import { EditaMonitor } from "./pages/Pessoa/Monitor/Edit";
import { AgendaManha } from "./pages/Agendamento/Manha";
import { AgendaRecepcaoManha } from "./pages/Agendamento/Recepacao_Manha";
import { Prontuario } from "./pages/Pessoa/Paciente/Prontuario";
import { AgendaTarde } from "./pages/Agendamento/Tarde";
import { AgendaRecepcaoTarde } from "./pages/Agendamento/Recepacao_Tarde";
import Agenda from "./pages/Agendamento/Agenda";
import AgendaRecepcao from "./pages/Agendamento/AgendaRecepcao";
import { GerenciaAtividade } from "./pages/Geral/Atividade/Gerencia";
import { AdicionaAtividade } from "./pages/Geral/Atividade/Add";
import { EditaAtividade } from "./pages/Geral/Atividade/Edit";
import { GerenciaAdministrativo } from "./pages/Pessoa/Administrativo/Gerencia";
import { AdicionaAdministrativo } from "./pages/Pessoa/Administrativo/Add";
import { EditaAdministrativo } from "./pages/Pessoa/Administrativo/Edit";
import { Welcome } from "./pages/Login/welcome";
import Relatorios from "./pages/Agendamento/Relatorios";

function App() {
  return (
    <div className="App min-h-screen flex flex-col">
      <header className="bg-white">
        <Header />
      </header>
      <div className="flex-grow">
        <hr />
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/bemvindo"
            element={
              <RequireAuth>
                <Welcome />
              </RequireAuth>
            }
          />
          <Route
            path="/perfil"
            element={
              <RequireAuth>
                <Perfil />
              </RequireAuth>
            }
          />
          <Route
            path="/paciente/*"
            element={
              <RequireAuth>
                <PacienteRoutes />
              </RequireAuth>
            }
          />
          <Route
            path="/sala/*"
            element={
              <RequireAuth>
                <SalaRoutes />
              </RequireAuth>
            }
          />
          <Route
            path="/especialidade/*"
            element={
              <RequireAuth>
                <EspecialidadeRoutes />
              </RequireAuth>
            }
          />
          <Route
            path="/convenio/*"
            element={
              <RequireAuth>
                <ConvenioRoutes />
              </RequireAuth>
            }
          />
          <Route
            path="/procedimento/*"
            element={
              <RequireAuth>
                <ProcedimentoRoutes />
              </RequireAuth>
            }
          />
          <Route
            path="/atividade/*"
            element={
              <RequireAuth>
                <AtividadeRoutes />
              </RequireAuth>
            }
          />
          <Route
            path="/terapeuta/*"
            element={
              <RequireAuth>
                <TerapeutaRoutes />
              </RequireAuth>
            }
          />
          <Route
            path="/auxiliar/*"
            element={
              <RequireAuth>
                <MonitorRoutes />
              </RequireAuth>
            }
          />
          <Route
            path="/administrativo/*"
            element={
              <RequireAuth>
                <AdministrativoRoutes />
              </RequireAuth>
            }
          />
          <Route
            path="/agenda"
            element={
              <RequireAuth terapeuta={1} monitor={1} administrativo={2}>
                <Agenda />
              </RequireAuth>
            }
          />
          <Route
            path="/agendarecepcao"
            element={
              <RequireAuth terapeuta={1} monitor={1} administrativo={1}>
                <AgendaRecepcao />
              </RequireAuth>
            }
          />
          <Route
            path="/relatorios"
            element={
              <RequireAuth terapeuta={1} monitor={1} administrativo={1}>
                <Relatorios />
              </RequireAuth>
            }
          />
          <Route
            path="/*"
            element={
              <RequireAuth>
                <Navigate to="/*" />
              </RequireAuth>
            }
          />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

function PacienteRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth terapeuta={1} monitor={1} administrativo={2}>
            <GerenciaPaciente />
          </RequireAuth>
        }
      />
      <Route
        path="/add"
        element={
          <RequireAuth terapeuta={0} monitor={0} administrativo={2}>
            <AdicionaPaciente />
          </RequireAuth>
        }
      />
      <Route
        path="/edit"
        element={
          <RequireAuth terapeuta={0} monitor={0} administrativo={2}>
            <EditaPaciente />
          </RequireAuth>
        }
      />
      <Route
        path="/prontuario"
        element={
          <RequireAuth terapeuta={1} monitor={0} administrativo={2}>
            <Prontuario />
          </RequireAuth>
        }
      />
    </Routes>
  );
}

function SalaRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth terapeuta={1} monitor={1} administrativo={3}>
            <GerenciaSala />
          </RequireAuth>
        }
      />
      <Route
        path="/add"
        element={
          <RequireAuth terapeuta={0} monitor={0} administrativo={3}>
            <AdicionaSala />
          </RequireAuth>
        }
      />
      <Route
        path="/edit"
        element={
          <RequireAuth terapeuta={0} monitor={0} administrativo={3}>
            <EditaSala />
          </RequireAuth>
        }
      />
    </Routes>
  );
}

function EspecialidadeRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth terapeuta={1} monitor={1} administrativo={3}>
            <GerenciaEspecialidade />
          </RequireAuth>
        }
      />
      <Route
        path="/add"
        element={
          <RequireAuth terapeuta={0} monitor={0} administrativo={3}>
            <AdicionaEspecialidade />
          </RequireAuth>
        }
      />
      <Route
        path="/edit"
        element={
          <RequireAuth terapeuta={0} monitor={0} administrativo={3}>
            <EditaEspecialidade />
          </RequireAuth>
        }
      />
    </Routes>
  );
}

function ConvenioRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth terapeuta={1} monitor={1} administrativo={3}>
            <GerenciaConvenio />
          </RequireAuth>
        }
      />
      <Route
        path="/add"
        element={
          <RequireAuth terapeuta={0} monitor={0} administrativo={3}>
            <AdicionaConvenio />
          </RequireAuth>
        }
      />
      <Route
        path="/edit"
        element={
          <RequireAuth terapeuta={0} monitor={0} administrativo={3}>
            <EditaConvenio />
          </RequireAuth>
        }
      />
    </Routes>
  );
}

function ProcedimentoRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth terapeuta={1} monitor={1} administrativo={3}>
            <GerenciaProcedimento />
          </RequireAuth>
        }
      />
      <Route
        path="/add"
        element={
          <RequireAuth terapeuta={0} monitor={0} administrativo={3}>
            <AdicionaProcedimento />
          </RequireAuth>
        }
      />
      <Route
        path="/edit"
        element={
          <RequireAuth terapeuta={0} monitor={0} administrativo={3}>
            <EditaProcedimento />
          </RequireAuth>
        }
      />
    </Routes>
  );
}

function AtividadeRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth terapeuta={1} monitor={1} administrativo={3}>
            <GerenciaAtividade />
          </RequireAuth>
        }
      />
      <Route
        path="/add"
        element={
          <RequireAuth terapeuta={0} monitor={0} administrativo={3}>
            <AdicionaAtividade />
          </RequireAuth>
        }
      />
      <Route
        path="/edit"
        element={
          <RequireAuth terapeuta={0} monitor={0} administrativo={3}>
            <EditaAtividade />
          </RequireAuth>
        }
      />
    </Routes>
  );
}

function TerapeutaRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth terapeuta={1} monitor={1} administrativo={2}>
            <GerenciaTerapeuta />
          </RequireAuth>
        }
      />
      <Route
        path="/add"
        element={
          <RequireAuth terapeuta={0} monitor={0} administrativo={2}>
            <AdicionaTerapeuta />
          </RequireAuth>
        }
      />
      <Route
        path="/edit"
        element={
          <RequireAuth terapeuta={0} monitor={0} administrativo={2}>
            <EditaTerapeuta />
          </RequireAuth>
        }
      />
    </Routes>
  );
}

function MonitorRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth terapeuta={1} monitor={1} administrativo={2}>
            <GerenciaMonitor />
          </RequireAuth>
        }
      />
      <Route
        path="/add"
        element={
          <RequireAuth terapeuta={0} monitor={0} administrativo={2}>
            <AdicionaMonitor />
          </RequireAuth>
        }
      />
      <Route
        path="/edit"
        element={
          <RequireAuth terapeuta={0} monitor={0} administrativo={2}>
            <EditaMonitor />
          </RequireAuth>
        }
      />
    </Routes>
  );
}

function AdministrativoRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth terapeuta={1} monitor={1} administrativo={2}>
            <GerenciaAdministrativo />
          </RequireAuth>
        }
      />
      <Route
        path="/add"
        element={
          <RequireAuth terapeuta={0} monitor={0} administrativo={2}>
            <AdicionaAdministrativo />
          </RequireAuth>
        }
      />
      <Route
        path="/edit"
        element={
          <RequireAuth terapeuta={0} monitor={0} administrativo={2}>
            <EditaAdministrativo />
          </RequireAuth>
        }
      />
    </Routes>
  );
}

export default App;
